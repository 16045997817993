import React, { useContext, useState } from "react";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import CloseIcon from '../../Assets/Images/FormCloseButton.svg'
import EyeIcon from '../../Assets/Images/CloseEyeIcon.svg'
import CopyIcon from '../../Assets/Images/CopyIcon.svg'
import Stack from '@mui/material/Stack';
import { alertClasses } from "@mui/material";
import { UseContext } from "../Context/Context";
import Toaster from "../Toaster/Toaster";
import { CreateNewQc } from "../BaseApi/Baseapi";
import Openeyeicon from '../../Assets/Images/googleye.svg'

const style = {
    position: 'relative',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '40%',
    bgcolor: 'background.paper',
    // height: '95%',
    boxShadow: 24,
    p: 4,
    outline: 'none',

};

const FormStyle = {

    positions: 'absolute',
    width: `100%`,
    margin: '-10px',
    display: 'flex',
    aligItems: 'center',
    justifyContent: 'space-between',
    height: '44px',

};

const FormStyle2 = {
    positions: 'absolute',
    width: `100%`,
    margin: '-10px',
    marginTop: '34px',

}

const Buttunstyle = {
    width: '100%',
    position: 'relative',
    borderRadius: '4px',
    bgcolor: '#fec83b',
    border: '1px #fec83b',
    boxSizing: 'border-box',
    height: '44px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '10px 45px',
    fontSize: '16px',
    color: '#fff',
    fontFamily: 'Poppins',
    '&:hover': {

        backgroundColor: '#fec83b',
    },

}



function NewUser({ props }) {


    const [qcName, setQcName] = useState('');
    const [qcEmail, setQcEmail] = useState('');
    const [qcPassword, setQcPassword] = useState('');
    const [qcConfirmPassword, setQcConfirmPassword] = useState('');
    const [showpssword, setShowpssword] = useState(false);
    const [showconfirmpssword, setShowconfirmpssword] = useState(false);
    const [emailerror, setemailerror] = useState('');
    const [confirm, setConfirm] = useState('');
    const [passworderror, setpasswordError] = useState('');
    const [confirmPasswordcolor, setConfirmPasswordcolor] = useState('');
    const [qccreatesuccessful, setQccreatesuccessful] = useState(false);
    const [error, setError] = useState('');
    const [passwordcolor, setPasswordcolor] = useState('');
    const [copypassword, setCopypassword] = useState('');

    const { qcbkName, setQcbkName,
        qcbkRoll, setQcbkRoll,
        newQc, setNewQc, } = useContext(UseContext)

    const HandelCreateQc = async (e) => {
        e.preventDefault();

        const nameParts = qcName.trim().split(' ');
        if (qcPassword !== qcConfirmPassword) {
            setConfirm('Password does not match');
            setConfirmPasswordcolor('red');


        } else {
            setConfirm('Password  match');
            setConfirmPasswordcolor('green');

            if (qcName == '' || qcEmail == '' || qcPassword == '' || qcConfirmPassword == '') {



                if (qcEmail == '') {
                    setemailerror('Please enter Email');
                } else {
                    setemailerror('');
                };


                if (qcPassword == '') {
                    setpasswordError('Please enter password');
                    setPasswordcolor('red')
                } else {
                    setpasswordError('');
                };

                if(qcConfirmPassword==''){
                    setConfirmPasswordcolor('red');
                    setConfirm('Please enter ConfirmPassword');
                  }else{
                    setpasswordError('');
                  }


                if (nameParts.length < 2) {
                    setError('Please enter first name and last name.');
                } else if (nameParts.length > 2) {
                    setError('Only first name and last name are allowed.');
                } else if (nameParts.length ==2) {
                    setError('');
                    const [firstName, lastName] = nameParts;
                    // Proceed with form submission or other logic
                    console.log('Form submitted:', { firstName, lastName });
                };
            } else {
                if ( nameParts.length == 2) {



                    const AdminToken=sessionStorage.getItem('AdminToken');
                    try {
                        const Bodydata = {

                            qcName: qcName,
                            qcEmail: qcEmail,
                            qcPassword: qcPassword,
                            confirmPassword: qcConfirmPassword,
                            roleName: 'QC'
                        }

                        const Data = await fetch(`${CreateNewQc}`, {
                            method: "POST",
                            headers: {
                                'token': `${AdminToken}`,
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify(Bodydata)

                        });
                        const Responce = await Data.json();

                        setQcbkName(Responce.qcName);
                        setNewQc(Responce.qcId);
                        setQccreatesuccessful(true);
                        console.log(Responce, newQc);
                        props.setOpen(false);
                        setTimeout(() => {
                            window.location.reload();
                        }, 4000)


                    } catch (error) {
                        console.log(error);
                    }
                } else {
                    setError('Please enter first name and last name.');


                }
            }
        }





    }

    


    const HandelEMail = () => {
        if (qcEmail.length >= 0) {
            if (qcEmail == qcEmail.toUpperCase()) {
                setemailerror('Email should be in lower case')
            } else {
                setemailerror('')
            }
        }
    }

    const Handelpassword = () => {
        const hasUpperCase = /[A-Z]/.test(qcPassword);
        const hasLowerCase = /[a-z]/.test(qcPassword);
        const hasNumber = /\d/.test(qcPassword);

        if (qcPassword.length > 15) {
            setpasswordError('weak password');
            setPasswordcolor('red');
        } else if (!hasUpperCase || !hasLowerCase || !hasNumber) {
            setpasswordError('weak password');
            setPasswordcolor('red');
        } else {
            setpasswordError('Strong Password');
            setPasswordcolor('Green');
            // Clear error if all conditions are met
        }
    }


    const handleCopy = () => {
        navigator.clipboard.writeText(qcConfirmPassword)
            .then(() => {
                // Success feedback
                setCopypassword('Copied');
                ;
            })
            .catch(err => {
                // Error feedback
                setCopypassword('Failed to copy');

            });
    };


    return (
        <>

            <Modal
                open={props.open}

                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>

                    <Box sx={FormStyle} >
                        <div>
                            <Typography sx={{
                                fontSize: '24px',
                                fontWeight: '600',
                                fontFamily: 'Poppins',
                                color: '#2c3a52',
                            }}>
                                New QC
                            </Typography>
                            <Typography sx={{
                                position: 'relative',
                                fontSize: '14px',
                                fontFamily: 'Poppins',
                                color: '#2c3a52',

                            }}>
                                Setup Credentials
                            </Typography>
                        </div>
                        <div>
                            <img alt="close" height={'15px'} src={CloseIcon} style={{ cursor: 'pointer' }} onClick={props.handleClose}></img>
                        </div>


                    </Box>

                    <Box sx={FormStyle2}>
                        <div>
                            <form onSubmit={HandelCreateQc}>
                                <div class="form-group">
                                    <label for="exampleFormControlInput1"
                                        style={{
                                            fontSize: '20px',
                                            fontWeight: '500',
                                            fontFamily: 'Poppins',
                                            color: '#343434'
                                        }}
                                    >QC Name</label>
                                    <input type="text" value={qcName} onChange={(e) => setQcName(e.target.value)} class="form-control" id="exampleFormControlInput1" placeholder="E.g. John Snow"
                                        style={{
                                            borderStyle: 'none',
                                            outline: 'none',
                                            lineHeight: '31.39px',
                                            fontWeight: '500',
                                            fontFamily: 'Poppins',
                                            color: '#343434',
                                            fontSize: '16px',
                                            backgroundColor: "#F8F8F8"
                                        }}
                                    ></input>
                                </div>
                                <p class='text-danger'>{error}</p>
                                <div class="form-group">
                                    <label for="exampleFormControlInput1"
                                        style={{
                                            fontSize: '20px',
                                            fontWeight: '500',
                                            fontFamily: 'Poppins',
                                            color: '#343434'
                                        }}
                                    >Email</label>
                                    <input type="email" value={qcEmail} onChange={(e) => { setQcEmail(e.target.value); HandelEMail() }} class="form-control" id="exampleFormControlInput1" placeholder="E.g. John@example.com"
                                        style={{
                                            borderStyle: 'none',
                                            outline: 'none',
                                            lineHeight: '31.39px',
                                            fontWeight: '500',
                                            fontFamily: 'Poppins',
                                            color: '#343434',
                                            fontSize: '16px',
                                            backgroundColor: "#F8F8F8"
                                        }}
                                    ></input>
                                    <small style={{ color: 'red' }}>{emailerror}</small>
                                </div>

                                <div class="form-group">
                                    <label for="exampleFormControlInput1"
                                        style={{
                                            fontSize: '20px',
                                            fontWeight: '500',
                                            fontFamily: 'Poppins',
                                            color: '#343434'
                                        }}
                                    >Set Password</label>
                                    <div class="form-control d-flex align-items-center" style={{ backgroundColor: "#F8F8F8" }}>
                                        <input type={showpssword == true ? 'text' : "password"} value={qcPassword} onChange={(e) => { setQcPassword(e.target.value); Handelpassword() }} class="form-control" id="exampleFormControlInput1" placeholder="Minimum 8 letters"
                                            style={{
                                                borderStyle: 'none',
                                                outline: 'none',
                                                lineHeight: '31.39px',
                                                fontWeight: '500',
                                                fontFamily: 'Poppins',
                                                color: '#343434',
                                                fontSize: '16px',
                                                backgroundColor: "#F8F8F8"
                                            }}
                                        ></input>
                                        <img alt="icon" height={'24px'} src={showpssword ? Openeyeicon : EyeIcon} onClick={() => setShowpssword(!showpssword)}></img>
                                    </div>
                                    <small style={{ color: `${passwordcolor}` }}>{passworderror}</small>
                                </div>
                                <div style={{
                                    width: '100%',
                                    position: 'relative',
                                    fontSize: '11px',
                                    fontFamily: 'Poppins',
                                    color: '#3e3e3e',
                                }}>
                                    Password must contain: Min 8 Characters, Max 15 Characters, 1 Lowercase, 1 Uppercasse, 1 Number.
                                </div>
                                <div class="form-group">
                                    <label for="exampleFormControlInput1"
                                        style={{
                                            fontSize: '20px',
                                            fontWeight: '500',
                                            fontFamily: 'Poppins',
                                            color: '#343434'

                                        }}
                                    >Confirm Password</label>
                                    <div class="form-control d-flex align-items-center" style={{ backgroundColor: "#F8F8F8" }}>
                                        <input type={showconfirmpssword == true ? 'text' : "password"} value={qcConfirmPassword} onChange={(e) => setQcConfirmPassword(e.target.value)} class="form-control" id="exampleFormControlInput1" placeholder="Re-enter the password"
                                            style={{
                                                borderStyle: 'none',
                                                outline: 'none',
                                                lineHeight: '31.39px',
                                                fontWeight: '500',
                                                fontFamily: 'Poppins',
                                                color: '#343434',
                                                fontSize: '16px',
                                                backgroundColor: "#F8F8F8"
                                            }}
                                        ></input>
                                        <img alt="icon" height={'24px'} src={showconfirmpssword ? Openeyeicon : EyeIcon} onClick={() => setShowconfirmpssword(!showconfirmpssword)}></img>
                                    </div>
                                    <small style={{ color: `${confirmPasswordcolor}` }}>{confirm}</small>
                                </div>
                                <small>{copypassword}</small>
                                <div onClick={handleCopy} style={{
                                    position: 'relative',
                                    display: 'flex',
                                    justifyContent: "flex-end",
                                    gap: '10px',
                                    cursor: 'pointer',
                                }}>

                                    <img src={CopyIcon} alt="icon" height={'24px'}></img>
                                    <span
                                        style={{
                                            position: 'relative',
                                            fontSize: '16px',
                                            lineHeight: '31.39px',
                                            fontFamily: 'Poppins',
                                            color: '#343434',
                                        }}
                                    >Copy Password</span>
                                </div>

                                <Stack direction={'row'}>
                                    <Button sx={Buttunstyle} type="submit">Confirm</Button>
                                </Stack>
                            </form>
                        </div>
                    </Box>
                </Box >
            </Modal >
            <Toaster qccreatesuccessful={qccreatesuccessful}></Toaster>
        </>
    )
};

export default NewUser