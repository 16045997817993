import React, { useContext, useEffect, useState } from "react";
import Loginstyle from '../Lgin/Login.module.css'
import LoginImage from '../../Assets/Images/Singuplogo.png'
import Loctechlogo from '../../Assets/Images/LowTeclogo.png'
import GoogleIcon from '../../Assets/Images/Googlelogo.svg'
import Eyeicon from '../../Assets/Images/googleye.svg'
import { useNavigate, useNavigation } from "react-router-dom";
import { UseContext } from "../Context/Context";
import { AdminLogin, GetAccessForNewAdmin } from "../BaseApi/Baseapi";
import { QcLogin } from "../BaseApi/Baseapi";
import { InternLogin } from "../BaseApi/Baseapi";
import Toaster from "../Toaster/Toaster";
import CloseEyeIcon from '../../Assets/Images/CloseEyeIcon.svg'
import { toast, Zoom } from "react-toastify";


function LoginPage() {
    const { setAdminName, setUserStatus,
        userStatus, adminName,
        loginEmail, setLoginEmail,
        loginpassword, setLoginPassword,
        setIsAuthorized,isAuthorized,
    } = useContext(UseContext)

    const [showpssword, setShowpssword] = useState(false);
    const [loginstatus, setLoginstatus] = useState(0);
    const Navigated = useNavigate();
    const [isSelectRole,setIsSelectRole]=useState(true);
    const [loginsuccessfully, setLoginsuccessfully] = useState(false);
    const [loginerror, setLoginerror] = useState(false);
    const [emailcaseType, setEmailcaseType] = useState(false);
    
//    Below function for TO Give Access To New User 

 const HandelAccessmangment=async(roleName)=>{
      try{
        const API=await fetch(`${GetAccessForNewAdmin}${roleName}`,{
            method:"GET",
            headers: {
                "content-type": "application/json",
              },
        })
        const Responce=await API.json();
        sessionStorage.setItem('Access',Responce.access)
      }catch(error){    

      }
 }
 const HandelAdminLogin = async (e) => {
    e.preventDefault();

    if (loginstatus === 0 && loginEmail !== '' && loginpassword !== '') {
        setIsSelectRole(!isSelectRole);
    } else {
        const bodydata = {
            email: loginEmail,
            password: loginpassword,
        };

        try {
            const Data = await fetch(`${AdminLogin}`, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(bodydata),
            });

            if (!Data.ok) {
                if (Data.status === 504) {
                    toast.info('Please Try after some time', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: 0,
                        theme: "colored",
                        transition: Zoom, 
                        style: { backgroundColor: '#fec83b', color: 'white' }  
                      });
                }
                throw new Error(`HTTP error! status: ${Data.status}`);
            }

            const Responce = await Data.json();
            console.log(Responce);

            if (Responce.errorCode === 409) {
                
                setLoginerror(true);
            }

            if (loginEmail === Responce.email && loginpassword === Responce.password) {
                setAdminName(Responce.firstName);
                setUserStatus(Responce.roleName);
                sessionStorage.setItem('userName', Responce.firstName);
                sessionStorage.setItem('roleName', Responce.roleName);
                sessionStorage.setItem('AdminId', Responce.id);
                sessionStorage.setItem('AdminconfirmPassword', Responce.confirmPassword);
                sessionStorage.setItem('AdminEmail', Responce.email);
                sessionStorage.setItem('AdminSessionID', Responce.userSessionId);
                sessionStorage.setItem('AdminToken', Responce.token);
                setIsAuthorized(true);
                setLoginsuccessfully(true);
                HandelAccessmangment(Responce.roleName);

                setTimeout(() => {
                    const Access = sessionStorage.getItem('Access');
                    const accessLevels = Access ? Access.split(',').map(Number) : [];

                    const accessPaths = [
                        { access: 1, path: '/Dashboard' },
                        { access: 2, path: '/AdminTeam' },
                        { access: 3, path: '/ReviewList' },
                        { access: 4, path: '/Support' },
                    ];

                    const match = accessLevels
                        .map(level => accessPaths.find(item => item.access === level))
                        .find(Boolean);

                    Navigated(match ? match.path : '/Dashboard');
                }, 4000);
            }

            
        } catch (error) {
            console.error('Error during login:', error);
            toast.info('Invalid Email or Password !', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: 0,
                theme: "colored",
                transition: Zoom, 
                style: { backgroundColor: '#fec83b', color: 'white' }  
              });
        }
    }
};


const HandelQcLogin = async (e) => {
    e.preventDefault();

    // Check for initial conditions
    if (loginstatus === 0 && loginEmail !== '' && loginpassword !== '') {
        setIsSelectRole(!isSelectRole);
    } else {
        const bodydata = {
            qcEmail: loginEmail,
            qcPassword: loginpassword,
        };

        try {
            const Data = await fetch(`${QcLogin}`, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(bodydata),
            });

            // Check if the response is not OK
            if (!Data.ok) {
                if (Data.status === 504) {
                    toast.info('Please Try after some time', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: 0,
                        theme: "colored",
                        transition: Zoom, 
                        style: { backgroundColor: '#fec83b', color: 'white' }  
                      });
                }
                throw new Error(`HTTP error! status: ${Data.status}`);
            }

            const Responce = await Data.json();
            console.log(Responce);

            // Check login credentials and set session storage
            if (Responce.qcConfirmPassword === loginpassword && Responce.qcEmail === loginEmail) {
                sessionStorage.setItem('QcName', Responce.qcName);
                sessionStorage.setItem('QcRoll', Responce.roleName);
                sessionStorage.setItem('QcID', Responce.qcId);
                sessionStorage.setItem('qcEmail', Responce.qcEmail);
                sessionStorage.setItem('QcconfirmPassword', Responce.confirmPassword);
                sessionStorage.setItem('QcsessionID', Responce.qcSessionId);
                sessionStorage.setItem('QcToken', Responce.token);

                setLoginsuccessfully(true);

                // Navigate after a delay
                setTimeout(() => {
                    Navigated('/Dashboard-Qc');
                }, 4000);
            }

            // Handle error code 409
            if (Responce.errorCode === 409) {
                setLoginerror(!loginerror);
            }
        } catch (error) {
            console.error('Error during QC login:', error);
            toast.info('Invalid Email or Password !', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: 0,
                theme: "colored",
                transition: Zoom, 
                style: { backgroundColor: '#fec83b', color: 'white' }  
              });
        }
    }
};



const HandelInternLogin = async (e) => {
    e.preventDefault();

    // Check for initial conditions
    if (loginstatus === 0 && loginEmail !== '' && loginpassword !== '') {
        setIsSelectRole(!isSelectRole);
    } else {
        try {
            const bodydata = {
                internEmail: loginEmail,
                internPassword: loginpassword,
            };

            const Data = await fetch(`${InternLogin}`, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(bodydata),
            });

            // Check if the response is not OK
            if (!Data.ok) {
                if (Data.status === 504) {
                    toast.info('Please Try after some time', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: 0,
                        theme: "colored",
                        transition: Zoom, 
                        style: { backgroundColor: '#fec83b', color: 'white' }  
                      });
                }
                throw new Error(`HTTP error! status: ${Data.status}`);
            }

            const Responce = await Data.json();
            console.log(Responce);

            // Validate the response for intern login
            if (
                Responce.roleName === 'Intern' &&
                Responce.internEmail === loginEmail &&
                Responce.confirmPassword === loginpassword
            ) {
                sessionStorage.setItem('InternName', Responce.internName);
                sessionStorage.setItem('InternRoll', Responce.roleName);
                sessionStorage.setItem('InternEmail', Responce.internEmail);
                sessionStorage.setItem('InternConfirmPassword', Responce.internConfirmPassword);
                sessionStorage.setItem('QcName', Responce.qcName); 
                sessionStorage.setItem('QcRoll', Responce.roleName); 
                sessionStorage.setItem('InterId', Responce.internId);
                sessionStorage.setItem('InternSessionID', Responce.internSessionId);
                sessionStorage.setItem('InternToken', Responce.token);

                setIsAuthorized(true);
                setLoginsuccessfully(true);

                // Navigate after a delay
                setTimeout(() => {
                    Navigated('/InternDashboard');
                }, 4000);
            }

            // Handle error code 409 (e.g., login conflict)
            if (Responce.errorCode === 409) {
                setLoginerror(!loginerror);
            }
        } catch (error) {
            console.error('Error during intern login:', error);
            toast.info('Invalid Email or Password !', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: 0,
                theme: "colored",
                transition: Zoom, 
                style: { backgroundColor: '#fec83b', color: 'white' }  
              });
        }
    }
};


    const forgotpassword=()=>{
        Navigated('/resetpassword')
    };
    
    
    console.log('loginstatus', loginstatus,isSelectRole);

   
    useEffect(()=>{
        if(loginEmail!=''){
            if(loginEmail==loginEmail.toUpperCase()){
                setEmailcaseType(!emailcaseType)
                
            }
        }
       
    },[loginEmail])
    return (
        <>
            <div className={Loginstyle.Main}>
                <div className={Loginstyle.Div1}>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <img alt="logo" src={LoginImage} className={Loginstyle.Image}></img>
                    </div>
                </div>
                <div className={Loginstyle.Div2}>
                    <div className={Loginstyle.Div3}>
                        <div className={Loginstyle.Div5}>
                            <div>
                                <img alt="logo" src={Loctechlogo} width={'60px'}></img>
                            </div>
                            <div>
                                <div className={Loginstyle.welcometext}>Welcome!! </div>
                                <div className={Loginstyle.logintext}>Login to Law Tech Platform</div>
                            </div>


                        </div>
                        <div className={Loginstyle.Div4}>
                            <span className={Loginstyle.logintext}>Login as:</span>
                            <div className={Loginstyle.Options}>
                                <div class='d-flex align-items-center gap-2'>
                                    <input type="radio" onClick={() => setLoginstatus(1)} name="login" id="intern" value='intern'></input>
                                    <label for="intern">Intern</label>
                                </div>
                                <div class='d-flex align-items-center gap-2'>
                                    <input type="radio" onClick={() => setLoginstatus(2)} name="login" id="Qc" value='Qc'></input>
                                    <label for="Qc">QC</label>
                                </div>
                                <div class='d-flex align-items-center gap-2'>
                                    <input type="radio" onClick={() => setLoginstatus(3)} name="login" id="Admin" value="Admin"></input>
                                    <label for="Admin">Admin</label>
                                </div>
                            </div>

                        </div>
                        <div className={Loginstyle.Form}>
                            <form onSubmit={loginstatus == 3 ? HandelAdminLogin : loginstatus == 1 ? HandelInternLogin : HandelQcLogin}>
                                <div class="form-group">
                                    <label for="exampleInputEmail1" className={Loginstyle.EmailText}>Email address <span style={{ color: 'red' }}>*</span></label>
                                    <div className={Loginstyle.InputDiv}>
                                        <input  type="email" value={loginEmail} onChange={(e) => setLoginEmail(e.target.value)} id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email" style={{ borderStyle: 'none', outline: 'none', backgroundColor: 'transparent', width: '90%', overflow: 'hidden' }}></input>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="exampleInputEmail1" className={Loginstyle.EmailText}>Enter  Password <span style={{ color: 'red' }}>*</span></label>
                                    <div className={Loginstyle.InputDiv} >
                                        <input type={showpssword == true ? 'text' : "password"} value={loginpassword} onChange={(e) => setLoginPassword(e.target.value)} id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Password" style={{ borderStyle: 'none', outline: 'none', backgroundColor: 'transparent', width: '90%', overflow: 'hidden' }}></input>
                                        <img src={showpssword == true ?  Eyeicon:CloseEyeIcon} height={'20px'} onClick={() => setShowpssword(!showpssword)}></img>
                                    </div>
                                </div>
                                <div class='d-flex justify-content-between align-items-center'>
                                    <div class='d-flex align-items-center gap-3'>
                                        <input type="checkbox"></input>
                                        <span className={Loginstyle.rememberMe}>Remember me</span>
                                    </div>
                                    {/* <div>
                                        <span className={Loginstyle.forgotPassword} onClick={forgotpassword} style={{cursor:'pointer'}}>
                                            Forgot Password?
                                        </span>

                                    </div> */}
                                </div>
                                <div class='mt-5' style={{cursor:'pointer'}}>
                                    <button className={Loginstyle.Loginbutton} type="submit" disabled={loginEmail!=''&&loginpassword!=''?false:true}> Login</button>
                                </div>
                            </form>
                        </div>


                    </div>
                </div>
            </div>

            <Toaster isSelectRole={isSelectRole} loginsuccessfully={loginsuccessfully} loginerror={loginerror} emailcaseType={emailcaseType} />
        </>
    )
};


export default LoginPage;