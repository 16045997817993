import React, { useContext, useEffect, useRef, useState } from 'react';
import SideBar from '../Sidebar/SIdebar';
import Dashboardstyle from '../Dashboard/Dashboard.module.css'
import QCStyle from '../QC/QCDashboard/QCDashboard.module.css';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { DateRangePicker } from '@mui/x-date-pickers-pro';
import { SingleInputDateRangeField } from '@mui/x-date-pickers-pro';
import Profile from '../../Assets/Images/Profilephoto1.png'
import RightArrow from '../../Assets/Images/RightArrow.svg'
import dayjs from 'dayjs';
import { Calendar } from 'primereact/calendar';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { styled } from '@mui/material/styles';
import DashboardIcon1 from '../../Assets/Images/DashboardIcon1.svg'
import ProfilePhoto1 from '../../Assets/Images/Profilephoto1.png'
import { UseContext } from '../Context/Context';
import Dropdown from 'react-bootstrap/Dropdown';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Dropdown1 from '../../Assets/Images/Dropdown Icon.svg'
import QcProfile from '../../Assets/Images/Profilephoto1.png'
import { GetDataonDateRange, GetInternListByQcId, GetQcDropdown, GetTotalCountCAR, GetTotalCountforAssignJudgment, TransferJudgmentFromQCorIntern, TransferJudgmentToQCorIntern } from '../BaseApi/Baseapi';
import { set } from 'date-fns';
import Toaster from '../Toaster/Toaster';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { enUS } from 'date-fns/locale';
import 'react-date-range/dist/styles.css'; 
import 'react-date-range/dist/theme/default.css'; 
import { DateRange, DateRangePicker } from 'react-date-range';
import { format } from 'date-fns';
import CircularProgress from '@mui/material/CircularProgress';





const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: 'white',

    padding: theme.spacing(1),
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: 90,
}));

function Dashboard() {
    const { isAuthorized } = useContext(UseContext);
    const [buttoncolor, setbuttoncolor] = useState(1);
    const [totalassingjudgmentnum, settotalassingjudgmentnum] = useState(0);
    const [totalfilecompletednum, settotalfilecompletednum] = useState(0);
    const [totalqcapprovednum, settotalqcapprovednum] = useState(0);
    const [totalqcrejectnum, settotalqcrejectnum] = useState(0);
    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [formattedDates, setFormattedDates] = useState({ startDate: '', endDate: '' });
    const [qcdropdownlist, setQcDropdownList] = useState([]);
    const [selectedqc, setSelectedQc] = useState('');
    const [qcid, setQcId] = useState('');
    const [internlist, setInternList] = useState([]);
    const [selectintern, setSelectIntern] = useState('');
    const [internId, setInternId] = useState('');
    const [rangeData, setRangeData] = useState([]);
    const [internrangedata, setInternRangedata] = useState();
    const [selectusertype, setSelectUserType] = useState(false);
    const [usertype, setusertype] = useState('');
    const [errorcode, setErrorcode] = useState();
    const { adminName } = useContext(UseContext);
    const [age, setAge] = useState('');


    // -------------------------------------------------------transaferjudgments State
    const [selectQcfrom, setSelectQcfrom] = useState([]);
    const [selectQcTo, setSelectQcTo] = useState([]);
    const [selectedQcfrom, setSelectedQcfrom] = useState('');
    const [selectedQcTo, setSelectedQcTo] = useState('');
    const [selectedQCfromid, setSelectedQCfromid] = useState('');
    const [selectedQCtoid, setSelectedQCtoid] = useState('');
    const [selectInternFrom, setSelectInternFrom] = useState([]);
    const [selectedinternfrom, setSelectedInternFrom] = useState('');
    const [selectInternTo, setSelectInternTo] = useState([]);
    const [selectedinternTo, setSelectedInternTo] = useState('');
    const [selectedInternFormID, setSelectedInternFormID] = useState('');
    const [selectedInternToID, setSelectedInternToID] = useState('');
    const [transferJudgment, setTransferJudgment] = useState([]);
    const [selectedTransferJudgment, setSelectedTransferJudgment] = useState([]);
    const [numerselectedtransferjudgment, setNumerSelectedTransferJudgment] = useState('');
    const [transferJudgmentId, setTransferJudgmentId] = useState('');
    const [judgmentassingsuccessfully, setJudgmentassingsuccessfully] = useState();

   
    const handleChange = (event) => {
        setAge(event.target.value);
    };


    const GetAssingJudgment = async () => {
        try {
            const Data = await fetch(`${GetTotalCountforAssignJudgment}`, {
                method: "GET",
                headers: {
                    'content-type': 'application/json'
                }
            })
            const Responce = await Data.json();
            settotalassingjudgmentnum(Responce)
        } catch (error) {
            console.log(error);
        }
    };

    const GetCAR = async () => {
        const AdminToken=sessionStorage.getItem('AdminToken');
        try {
            const Data = await fetch(`${GetTotalCountCAR}`, {
                method: "GET",
                headers: {
                    'token': `${AdminToken}`,
                    'content-type': 'application/json',
                    
                }
            })
            const Responce = await Data.json();
            settotalassingjudgmentnum(Responce?.totalJudgementSum);
            settotalqcapprovednum(Responce?.approvedCount);
            settotalfilecompletednum(Responce?.completedCount);
            settotalqcrejectnum(Responce?.rejectedCount);

        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        // GetAssingJudgment();
        GetCAR();
    }, []);





    const HandelselectDateRange = (newValue) => {
        if (newValue) {
            const [start, end] = newValue;

            setDateRange(newValue); // Set the raw date values for DateRangePicker

            const formattedStartDate = start ? dayjs(start).format('DD MMM YYYY') : '';
            const formattedEndDate = end ? dayjs(end).format('DD MMM YYYY') : '';

            setFormattedDates({ startDate: formattedStartDate, endDate: formattedEndDate });
            console.log('Formatted Dates:', formattedStartDate, formattedEndDate);

        }
    };

    const HandelQcDropdownlist = async () => {
        const AdminToken=sessionStorage.getItem('AdminToken');
        try {
            const adminId = sessionStorage.getItem('AdminId')
            const Data = await fetch(`${GetQcDropdown}`,
                {
                    method: "GET",
                    headers: {
                        'token': `${AdminToken}`,
                        'content-type': 'application/json'
                    }
                }
            )
            var ResponceList = await Data.json();
            setQcDropdownList(ResponceList);
            setSelectIntern('');

        } catch (error) {

        }
    };

    const HandelInternList = async () => {
        const AdminToken=sessionStorage.getItem('AdminToken');
        try {
            const Data = await fetch(`${GetInternListByQcId}${qcid}`,{
                method: "GET",
                headers: {
                    'token': `${AdminToken}`,
                    'content-type': 'application/json'
                }
            })
            var Responce = await Data.json();
            setInternList(Responce);

        } catch (error) {

        }
    };

    const HandelGetDataOnRangeQC = async (ID) => {
        const AdminToken=sessionStorage.getItem('AdminToken');
        try {
            const Data = await fetch(`${GetDataonDateRange}/${startDate}/${endDate}/qc/${ID}`, {
                method: "GET",
                headers: {
                    'token': `${AdminToken}`,
                    'content-type': 'application/json'
                }
            })
            const responce = await Data.json();
            setRangeData(responce);
            if (responce.errorCode === 400) {
                setErrorcode(true);
            } else {
                setErrorcode(false);
            }
        } catch (error) {
            // alert(error)

        }

    }

    const HandelGetDataOnRangeIntern = async (ID) => {
        const AdminToken=sessionStorage.getItem('AdminToken');
        try {
            const Data = await fetch(`${GetDataonDateRange}/${startDate}/${endDate}/intern/${ID}`, {
                method: "GET",
                headers: {
                     'token': `${AdminToken}`,
                    'content-type': 'application/json'
                }
            })
            const responce = await Data.json();
            setRangeData(responce);
            if (responce.errorCode === 400) {
                setErrorcode(true);
            } else {
                setErrorcode(false);
            }
        } catch (error) {
            // alert(error)
        }

    }

    

    const formatDate = (timestamp) => {
        const date = new Date(timestamp);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const year = date.getFullYear();

        return `${day}/${month}/${year}`;
    };

    // -------------------------------API Cal For TO Select Qc From  Judgemnt Transafer----------------------------------
    const HandeleSelectQcFrom = async () => {
        const AdminToken=sessionStorage.getItem('AdminToken');
        try {
            const API = await fetch(`${GetQcDropdown}`, {
                method: "GET",
                headers: {
                    'token': `${AdminToken}`,
                    'content-type': 'application/json'
                }
            })
            const Responce = await API.json();
            setSelectQcfrom(Responce);
        } catch (error) {

        }

    };

    const HandeleSelectQcTO = async () => {
        const AdminToken=sessionStorage.getItem('AdminToken');
        try {
            const API = await fetch(`${GetQcDropdown}`, {
                method: "GET",
                headers: {
                    'token': `${AdminToken}`,
                    'content-type': 'application/json'
                }
            })
            const Responce = await API.json();
            setSelectQcTo(Responce);
        } catch (error) {

        }

    };

    //  --------------------------------------------  API Call for to Select Intern for JUdgment Transafer----------------------------------------------------------------
    const HandeleSelectInternFrom = async () => {

        if (selectedQCfromid !== '') {
            const AdminToken=sessionStorage.getItem('AdminToken');
            try {
                const API = await fetch(`${GetInternListByQcId}${selectedQCfromid}`, {
                    method: "GET",
                    headers: {
                        'token': `${AdminToken}`,
                        'content-type': 'application/json'
                    }
                })
                const Responce = await API.json();
                setSelectInternFrom(Responce);
            } catch (error) {

            }
        } else {

        }


    };

    const HandeleSelectInternTO = async () => {
        const AdminToken=sessionStorage.getItem('AdminToken');
        if (selectedQCtoid !== '') {
            try {
                const API = await fetch(`${GetInternListByQcId}${selectedQCtoid}`, {
                    method: "GET",
                    headers: {
                        'token': `${AdminToken}`,
                        'content-type': 'application/json'
                    }
                })
                const Responce = await API.json();
                setSelectInternTo(Responce);
            } catch (error) {

            }
        } else {

        }


    };

    // -----------------------------------------------API Call Form to Select Judgment form QC+Intern  TO Transfer -------------------------------------
    const HandelSelectTransferFormJudgment = async () => {
        const AdminToken=sessionStorage.getItem('AdminToken');
        if (selectedQCfromid !== '' && selectedInternFormID !== '') {
            try {
                const API = await fetch(`${TransferJudgmentFromQCorIntern}${selectedQCfromid}/${selectedInternFormID}`, {
                    method: "GET",
                    headers: {
                        'token': `${AdminToken}`,
                        'content-type': 'application/json'
                    }
                })
                const Responce = await API.json();
                setTransferJudgment(Responce);
            } catch (error) {
                console.log(error);
            }
        } else {

        }



    };

    // --------------------------------------------API Call For To Transfer Selected Judgment TO Intern -------------------------------------
    const HandelSelectTransferToJudgment = async () => {
        const AdminToken=sessionStorage.getItem('AdminToken');
        try {
            const API = await fetch(
                `${TransferJudgmentToQCorIntern}qcIdFrom=${selectedQCfromid}&interIdFrom=${selectedInternFormID}&qcIdTo=${selectedQCtoid}&interIdTo=${selectedInternToID}&id=${transferJudgmentId}&judgmentNo=${numerselectedtransferjudgment}`,
                {
                    method: "PUT",
                    headers: {
                        'token': `${AdminToken}`,
                        "content-type": "application/json",
                    },
                }
            );

            // Check if the response status is ok (successful)
            if (API.ok) {
                // Check if the response is JSON by inspecting the content-type header
                const contentType = API.headers.get("content-type");
                if (contentType && contentType.includes("application/json")) {
                    const response = await API.json(); // Parse as JSON
                    console.log("Success:", response);
                } else {
                    const responseText = await API.text(); // Parse as text
                    console.log("Success (text response):", responseText);
                    setJudgmentassingsuccessfully(true);
                    setTimeout(() => {
                        window.location.reload();
                    }, 2000)

                }
            } else {
                console.warn(`Unexpected status: ${API.status}`);
            }
        } catch (error) {
            console.log("Error:", error);
        }
    };
    console.log(selectedTransferJudgment);


    // ------------------below function for to select date Range-----------------------
    const [selectionRange, setSelectionRange] = useState({
        startDate: 0,
        endDate: 0,
        key: 'selection',
    });
    const calendarRef = useRef(null);
    const [showcalendare, setShowCalendare] = useState();
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (calendarRef.current && !calendarRef.current.contains(event.target)) {
                setShowCalendare(false);
            }
        };


        document.addEventListener('mousedown', handleClickOutside);


        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const formatseletDate = (date) => {
        return date
            ? date.toLocaleDateString('en-GB', {
                day: '2-digit',
                month: 'short',
                year: 'numeric',
            })
            : '';
    };
    const handleSelect = (ranges) => {
        console.log(ranges); 
        setSelectionRange({
            startDate: ranges.selection.startDate,
            endDate: ranges.selection.endDate,
            key: 'selection',
        });

        const formattedStartDate = formatseletDate(ranges.selection.startDate);
        const formattedEndDate = formatseletDate(ranges.selection.endDate);
        setStartDate(formattedStartDate)
        setEndDate(formattedEndDate)
        console.log('Formatted Dates:', formattedStartDate, formattedEndDate);


    };

    return (
        <>
            {/* <SideBar></SideBar> */}
            <div className={Dashboardstyle.MainDiv} style={{ overflowX: "hidden" }}>
                <div className={Dashboardstyle.SideBar}>
                    <SideBar buttoncolor={buttoncolor} />
                </div>

                <div className={QCStyle.Dashboard} style={{ overflowY: 'scroll' }}>
                    <div className={QCStyle.Div1}>
                        <Box sx={{ flexGrow: 1, }} >
                            <Grid container spacing={2} >
                                <Grid item xs={3}>
                                    <Item >
                                        <div className={QCStyle.BoxValue}>{totalassingjudgmentnum ? <div>{totalassingjudgmentnum}</div> : <Box ><CircularProgress sx={{ color: '#ffc107' }} thickness={5} /></Box>}</div>
                                        <div className={QCStyle.BoxLabel}>Assigned Judgements</div>

                                    </Item>

                                </Grid>
                                <Grid item xs={3}>
                                    <Item>
                                        <div className={QCStyle.BoxValue}>
                                            {totalfilecompletednum && totalqcapprovednum && totalqcrejectnum ?
                                                <div>{totalfilecompletednum + totalqcapprovednum + totalqcrejectnum}</div> :
                                                <Box  >
                                                    <CircularProgress
                                                        sx={{ color: '#ffc107' }}
                                                        thickness={5} />
                                                </Box>}
                                        </div>
                                        <div className={QCStyle.BoxLabel}>File Received</div>

                                    </Item>
                                </Grid>
                                <Grid item xs={3}>
                                    <Item>
                                        <div style={{ color: "#008000" }} className={QCStyle.BoxValue}> {totalqcapprovednum ? <div>{totalqcapprovednum}</div> : <Box ><CircularProgress sx={{ color: '#008000' }} thickness={5} /></Box>}</div>
                                        <div className={QCStyle.BoxLabel}>QC Approved</div>

                                    </Item>
                                </Grid>
                                <Grid item xs={3}>
                                    <Item>
                                        <div className={QCStyle.BoxValue}> {totalqcrejectnum ? <div>{totalqcrejectnum}</div> : <Box ><CircularProgress sx={{ color: '#ffc107' }} thickness={5} /></Box>}</div>
                                        <div className={QCStyle.BoxLabel}>QC Rejected</div>

                                    </Item>
                                </Grid>
                            </Grid>
                        </Box>
                    </div>
                    <div className={QCStyle.Div1} style={{ backgroundColor: 'white', padding: '10px' }}>
                        <div style={{
                            margin: '10px'
                        }}>
                            <div>
                                <h5 style={{ fontSize: '16px', fontFamily: 'Poppins', color: '#424242', fontWeight: 600 }}>Transfer Assigned Judgements:</h5>
                            </div>
                            <div >
                                <p style={{ fontSize: '16px', fontFamily: 'Poppins', color: '#424242', fontWeight: 500 }}>From</p>

                                <div class='d-flex flex-row w-100 gap-3 align-items-center'>
                                    <FormControl
                                        sx={{
                                            '& .MuiOutlinedInput-root': {
                                                '&.Mui-focused fieldset': {
                                                    borderColor: '#fec83b',
                                                },
                                            },
                                            minWidth: '25%',
                                        }}
                                        onClick={HandeleSelectQcFrom} // This will fire when FormControl is clicked
                                    >
                                        <label
                                            id="demo-simple-select-label"
                                            style={{
                                                fontSize: '12px',
                                                fontFamily: 'Poppins',
                                                color: '#424242',
                                                fontWeight: 500,
                                            }}
                                        >
                                            Select QC
                                        </label>
                                        <Select
                                            value={selectedQcfrom}
                                            onChange={(e) => {


                                                HandeleSelectQcFrom();
                                            }}
                                            displayEmpty
                                            inputProps={{
                                                'aria-label': 'Without label',
                                                placeholder: 'Select QC',
                                            }}
                                            sx={{
                                                height: '40px',
                                                paddingTop: 1,
                                                paddingBottom: 1,
                                            }}
                                            onOpen={HandeleSelectQcFrom}
                                        >
                                            <MenuItem value="" disabled>
                                                Select QC
                                            </MenuItem>
                                            {selectQcfrom.map((item, index) => (
                                                <MenuItem key={index} value={item.qcName} onClick={() => { setSelectedQcfrom(item.qcName); setSelectedQCfromid(item.qcId) }}>
                                                    {item.qcName}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>


                                    <FormControl sx={{
                                        minWidth: '25%',
                                        '& .MuiOutlinedInput-root': {
                                            '&.Mui-focused fieldset': {
                                                borderColor: '#fec83b',
                                            },
                                        },
                                    }}>
                                        <label id="demo-simple-select-label" style={{ fontSize: '12px', fontFamily: 'Poppins', color: '#424242', fontWeight: 500 }}>
                                            Select Intern
                                        </label>

                                        <Select
                                            value={selectedinternfrom}
                                            displayEmpty
                                            inputProps={{
                                                'aria-label': 'Without label',
                                                placeholder: 'Select Intern',
                                            }}
                                            sx={{
                                                height: '40px',
                                                paddingTop: 1,
                                                paddingBottom: 1,
                                            }}
                                            onOpen={HandeleSelectInternFrom}  // Trigger the function when dropdown opens
                                        >
                                            {/* Default MenuItem for placeholder */}
                                            <MenuItem value="" disabled>
                                                Select Intern
                                            </MenuItem>

                                            {/* If there are no interns, show 'Intern Not Assigned' */}
                                            {selectInternFrom.length === 0 ? (
                                                <MenuItem disabled sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                    Intern Not Assigned
                                                </MenuItem>
                                            ) : (
                                                // Map through the interns if the list is not empty
                                                selectInternFrom.map((item, index) => (
                                                    <MenuItem
                                                        key={index}
                                                        value={item.internName}
                                                        onClick={() => {
                                                            setSelectedInternFrom(item.internName);
                                                            setSelectedInternFormID(item.internId);
                                                        }}
                                                    >
                                                        {item.internName}
                                                    </MenuItem>
                                                ))
                                            )}
                                        </Select>
                                    </FormControl>

                                    <FormControl sx={{
                                        '& .MuiOutlinedInput-root': {
                                            '&.Mui-focused fieldset': {
                                                borderColor: '#fec83b',
                                            },
                                        },
                                        minWidth: '30%'
                                    }}>
                                        <label id="demo-simple-select-label" style={{ fontSize: '12px', fontFamily: 'Poppins', color: '#424242', fontWeight: 500 }}>Assigned Judgements:</label>

                                        <Select
                                            value={selectedTransferJudgment}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                            sx={{
                                                height: '40px',
                                                paddingTop: 1,
                                                paddingBottom: 1,
                                            }}
                                            onOpen={HandelSelectTransferFormJudgment}
                                            renderValue={(selected) => {
                                                // Check if an item is selected, otherwise show placeholder
                                                if (!selected || !selected.assignedVolume) {
                                                    return 'Select Judgement';
                                                }

                                                // Customize the display of selected item
                                                return (
                                                    <span style={{ display: 'flex', gap: '30px', alignItems: 'center', justifyContent: 'space-between', }}>
                                                        <span>{selected.assignedVolume}</span>
                                                        <span>{selected.noOfJudgement}</span>
                                                        <span>{selected.part || 'null'}</span>
                                                        <span>{selected.assignedYear}</span>
                                                    </span>
                                                );
                                            }}
                                        >
                                            {transferJudgment.length === 0 ? (
                                                <MenuItem disabled sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                    No judgments assigned
                                                </MenuItem>
                                            ) : (

                                                <>
                                                    <MenuItem value="" disabled sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: '10px' }}>
                                                        Select Judgement
                                                    </MenuItem>
                                                    {transferJudgment.map((item, index) => (
                                                        <MenuItem
                                                            key={index}
                                                            value={item}
                                                            sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: '30px' }}
                                                            onClick={() => {
                                                                setSelectedTransferJudgment(item);
                                                                setNumerSelectedTransferJudgment(item.noOfJudgement);
                                                                setTransferJudgmentId(item.id);
                                                            }}
                                                        >
                                                            <span>{item.assignedVolume}</span>
                                                            <span>{item.noOfJudgement}</span>
                                                            <span>{item.part || 'null'}</span>
                                                            <span>{item.assignedYear}</span>
                                                        </MenuItem>
                                                    ))}
                                                </>
                                            )}
                                        </Select>


                                    </FormControl>
                                    <div style={{ width: '20%' }}>
                                        <label style={{ fontSize: '12px', fontFamily: 'Poppins', color: '#424242', fontWeight: 500 }}>Judgements Included</label>

                                        <div
                                            style={{
                                                height: '44px',
                                                width: '100%',
                                                backgroundColor: '#f5f5f5',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                color: '#5c5c5c',
                                                fontsize: '14px',
                                                fontFamily: 'Poppins',
                                            }}
                                        >
                                            {numerselectedtransferjudgment || '0'}

                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div >
                                <p style={{ fontSize: '16px', fontFamily: 'Poppins', color: '#424242', fontWeight: 500, marginTop: '20px' }}>TO</p>

                                <div class='d-flex flex-row w-100 gap-3 align-items-center'>
                                    <FormControl sx={{
                                        '& .MuiOutlinedInput-root': {
                                            '&.Mui-focused fieldset': {
                                                borderColor: '#fec83b',
                                            },
                                        },

                                        minWidth: '25%'
                                    }}
                                        onClick={HandeleSelectQcTO}
                                    >
                                        <label id="demo-simple-select-label" style={{ fontSize: '12px', fontFamily: 'Poppins', color: '#424242', fontWeight: 500 }}>Select QC</label>
                                        <Select
                                            value={selectedQcTo}

                                            displayEmpty
                                            inputProps={{
                                                'aria-label': 'Without label',
                                                placeholder: 'Select QC',

                                            }}
                                            sx={{
                                                height: '40px',
                                                paddingTop: 1,
                                                paddingBottom: 1,
                                            }}
                                            onOpen={HandeleSelectQcTO}
                                        >
                                            <MenuItem value="" disabled>
                                                Select QC
                                            </MenuItem>
                                            {selectQcTo?.map((item, index) => (
                                                <MenuItem key={index} value={item.qcName} onClick={() => { setSelectedQcTo(item.qcName); setSelectedQCtoid(item.qcId) }}>{item.qcName}</MenuItem>
                                            ))}


                                        </Select>

                                    </FormControl>
                                    <FormControl sx={{
                                        minWidth: '25%',
                                        '& .MuiOutlinedInput-root': {
                                            '&.Mui-focused fieldset': {
                                                borderColor: '#fec83b',
                                            },
                                        },
                                    }} onClick={HandeleSelectInternTO}>
                                        <label id="demo-simple-select-label" style={{ fontSize: '12px', fontFamily: 'Poppins', color: '#424242', fontWeight: 500 }}>
                                            Select Intern
                                        </label>

                                        <Select
                                            value={selectedinternTo}
                                            onChange={handleChange}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label', placeholder: 'Select Intern' }}
                                            sx={{
                                                height: '40px',
                                                paddingTop: 1,
                                                paddingBottom: 1,
                                            }}
                                            onOpen={HandeleSelectInternTO}
                                        >
                                            {/* Default placeholder */}
                                            <MenuItem value="" disabled>
                                                Select Intern
                                            </MenuItem>

                                            {/* Conditional rendering: If no interns, show 'Intern Not Assigned' */}
                                            {selectInternTo.length === 0 ? (
                                                <MenuItem disabled sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                    Intern Not Assigned
                                                </MenuItem>
                                            ) : (
                                                // Map through the interns if the list is not empty
                                                selectInternTo.map((item, index) => (
                                                    <MenuItem
                                                        key={index}
                                                        value={item.internName}
                                                        onClick={() => {
                                                            setSelectedInternTo(item.internName);
                                                            setSelectedInternToID(item.internId);
                                                        }}
                                                    >
                                                        {item.internName}
                                                    </MenuItem>
                                                ))
                                            )}
                                        </Select>
                                    </FormControl>


                                    <div style={{ width: '20%' }} onClick={HandelSelectTransferToJudgment}>
                                        <label style={{ fontSize: '12px', fontFamily: 'Poppins', color: '#424242', fontWeight: 500 }}></label>

                                        <button
                                            style={{
                                                height: '38px',
                                                width: '50%',
                                                backgroundColor: '#223a48',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                color: '#fff',
                                                fontsize: '14px',
                                                fontFamily: 'Poppins',
                                                borderRadius: '4px',
                                                borderColor: '1px solid #223a48',
                                                cursor: 'pointer',
                                            }}
                                            disabled={selectedQcTo && selectedinternTo ? false : true}

                                            onClick={HandelSelectTransferToJudgment}
                                        >
                                            Transafer

                                        </button>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className={QCStyle.Div2}>
                        <div className={QCStyle.Div3} style={{ width: selectusertype == false ? '50%' : '40%', display: 'flex', gap: "10rem" }} onClick={() => setShowCalendare(true)}>
                            <span style={{ fontSize: "14px" }}>This month:</span>  <span style={{ fontSize: "14px" }}>
                                {
                                    selectionRange.startDate && selectionRange.endDate
                                        ? `${format(selectionRange.startDate, 'dd MMM yyyy')} - ${format(selectionRange.endDate, 'dd MMM yyyy')}`
                                        : 'Select  Date  Range'
                                }



                            </span>

                        </div>
                        <div ref={calendarRef} style={{ position: 'absolute', left: '50%', transform: 'translateX(-50%)', display: showcalendare == true ? "block" : "none", boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)', opacity: 1000, zIndex: 1 }}>
                            <DateRange
                                ranges={[selectionRange]}
                                onChange={handleSelect}
                                locale={enUS}
                                moveRangeOnFirstSelection={true}
                                editableDateInputs={true}
                                


                            />
                        </div>
                        <div className={QCStyle.Div3} style={{ width: selectusertype == false ? '25%' : '20%', display: 'flex', justifyContent: 'space-between' }}>
                            <div style={{ width: '100%  ', display: 'flex', justifyContent: 'space-between' }} >

                                <Dropdown onToggle={selectionRange.startDate && selectionRange.endDate ? HandelQcDropdownlist : null} style={{ width: '100%' }}>

                                    <Dropdown.Toggle
                                        className={QCStyle.Div2Box}
                                        style={{ width: "100%", display: 'flex', alignItems: 'center', justifyContent: 'space-between', position: 'relative' }}
                                        variant="white"
                                        id="dropdown-basic"

                                    >

                                        <span style={{ fontSize: "12px" }} >
                                            {usertype ? usertype : 'Select User Type'}

                                        </span>

                                    </Dropdown.Toggle>
                                    <Dropdown.Menu
                                        className={QCStyle.dropdown}
                                        style={{ height: "fit-content", }}
                                    >

                                        <div style={{ position: 'relative', borderBottom: '1px solid #f5f5f5', }}>
                                            <Dropdown.Item onClick={() => { setSelectUserType(false); setusertype('QC') }}><p>QC</p></Dropdown.Item>
                                            <Dropdown.Item onClick={() => { setSelectUserType(true); setusertype('Intern') }}> <p>Intern</p></Dropdown.Item>
                                        </div>


                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </div>
                        <div className={QCStyle.Div3} style={{ width: selectusertype == false ? '25%' : '20%', display: 'flex', justifyContent: 'space-between' }}>
                            <div style={{ width: '100%  ', display: 'flex', justifyContent: 'space-between' }} >

                                <Dropdown onToggle={selectionRange.startDate && selectionRange.endDate ? HandelQcDropdownlist : null} style={{ width: '100%' }}>

                                    <Dropdown.Toggle
                                        className={QCStyle.Div2Box}
                                        style={{ width: "100%", display: 'flex', alignItems: 'center', justifyContent: 'space-between', position: 'relative' }}
                                        variant="white"
                                        id="dropdown-basic"

                                    >

                                        <span style={{ fontSize: "12px" }} onClick={selectionRange.startDate && selectionRange.endDate ? HandelQcDropdownlist : null}>
                                            {selectedqc ? selectedqc : "Select QC"}

                                        </span>

                                    </Dropdown.Toggle>
                                    <Dropdown.Menu
                                        className={QCStyle.dropdown}
                                        style={{ height: qcdropdownlist.length > 5 ? "50vh" : "fit-content", overflowY: 'scroll', width: selectusertype == false ? "100%" : '' }}
                                    >
                                        {qcdropdownlist.map((item, index) => (
                                            <div key={index} style={{ position: 'relative', borderBottom: '1px solid #f5f5f5', }} onClick={() => { setSelectedQc(item.qcName); setQcId(item.qcId); if (usertype === 'QC') { HandelGetDataOnRangeQC(item.qcId); } }}>
                                                <Dropdown.Item





                                                >
                                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', width: '100%', gap: '10px', cursor: 'pointer' }} onClick={() => { setSelectedQc(item.qcName); setQcId(item.qcId) }}>
                                                        <div style={{ width: '50%', position: 'relative', display: 'flex', alignItems: 'center', flexDirection: 'row', left: '-10px', gap: "5px", padding: "10px", paddingTop: "10px" }}>
                                                            <img
                                                                src={Profile}
                                                                alt="profile"
                                                                style={{ height: '24px', borderRadius: '50%' }}
                                                            />
                                                            <span>{item.qcName}</span>


                                                        </div>



                                                    </div>





                                                </Dropdown.Item>




                                            </div>

                                        ))}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </div>
                        <div className={QCStyle.Div3} style={{ width: '20%', display: selectusertype == false ? "none" : "" }} >
                            <div style={{ width: '100%  ', display: 'flex', justifyContent: 'space-between', }} >

                                <Dropdown onToggle={selectionRange.startDate && selectionRange.endDate ? HandelInternList : null} style={{ width: "100%" }}>

                                    <Dropdown.Toggle
                                        className={QCStyle.Div2Box}
                                        style={{ width: "100%", display: 'flex', alignItems: 'center', justifyContent: 'space-between', position: 'relative', }}
                                        variant="white"
                                        id="dropdown-basic"

                                    >

                                        <span style={{ fontSize: "12px" }} onClick={selectionRange.startDate && selectionRange.endDate ? HandelInternList : null}>
                                            {selectintern ? selectintern : "Select Intern"}

                                        </span>

                                    </Dropdown.Toggle>
                                    <Dropdown.Menu
                                        className={QCStyle.dropdown}
                                        style={{ height: qcdropdownlist.length > 5 ? "50vh" : "fit-content", overflowY: 'scroll', }}
                                    >
                                        {internlist.map((item, index) => (
                                            <div key={index} style={{ position: 'relative', borderBottom: '1px solid #f5f5f5', }} onClick={() => { setSelectIntern(item.internName); setInternId(item.internId); setInternRangedata(item.internName); HandelGetDataOnRangeIntern(item.internId) }}>
                                                <Dropdown.Item





                                                >
                                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', width: '100%', gap: '10px', cursor: 'pointer' }} onClick={() => { setSelectIntern(item.internName); setInternId(item.internId); setInternRangedata(item.internName) }}>
                                                        <div style={{ width: '25%', position: 'relative', display: 'flex', alignItems: 'center', flexDirection: 'row', left: '-10px', gap: "5px", padding: "10px", paddingTop: "10px" }}>
                                                            <img
                                                                src={Profile}
                                                                alt="profile"
                                                                style={{ height: '24px', borderRadius: '50%' }}
                                                            />
                                                            <span>{item.internName}</span>


                                                        </div>



                                                    </div>





                                                </Dropdown.Item>




                                            </div>

                                        ))}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </div>
                    </div>
                    <div className={QCStyle.Div4} style={{ display: rangeData.length > 0 ? '' : 'none', }}>

                        <>
                            <div style={{
                                position: 'relative',
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'space-between',
                                display: rangeData.length > 0 ? 'flex' : 'none',
                            }}>
                                <div class='d-flex align-items-center gap-3'>
                                    <div>
                                        <img src={QcProfile} style={{ height: "24px", borderRadius: "50%" }}></img>
                                    </div>
                                    <div class='d-flex flex-column'>
                                        <span
                                            style={{
                                                position: 'relative',
                                                fontSize: '14px',
                                                fontFamily: 'Poppins',
                                                color: '#424242',
                                            }}>{selectusertype == true ? internrangedata : selectedqc}</span>
                                        <span
                                            style={{
                                                position: 'relative',
                                                fontSize: '12px',
                                                fontweight: '500',
                                                fontFamily: 'Poppins',
                                                color: '#a9a9a9',
                                            }}>TEAM :{selectintern != '' ? selectedqc : ""} (QC)</span>

                                    </div>
                                </div>
                                <div>
                                    {errorcode == true ? "" : `${format(selectionRange.startDate, 'dd MMM yyyy')} - ${format(selectionRange.endDate, 'dd MMM yyyy')}`}
                                </div>
                            </div>
                            {errorcode == true ? '' : rangeData?.map((item, index) => (
                                <div key={index}
                                    style={{
                                        position: 'relative',
                                        width: '100%',
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                    }}>

                                    <div class='d-flex flex-column align-items-center'>
                                        <span
                                            style={{
                                                position: 'relative',
                                                Fontsize: '14px',
                                                fontweight: '500',
                                                fontFamily: 'Poppins',
                                                color: '#5c5c5c',
                                            }}>{item.judgementNo ? item.judgementNo : 0}</span>
                                        <span
                                            style={{
                                                fontsize: '12px',
                                                fontweight: '600',
                                                fontFamily: 'Poppins',
                                                color: '#a9a9a9',
                                            }}>No. of Judgement</span>
                                    </div>


                                    <div class='d-flex flex-column align-items-center'>
                                        <span
                                            style={{
                                                position: 'relative',
                                                Fontsize: '14px',
                                                fontweight: '500',
                                                fontFamily: 'Poppins',
                                                color: '#5c5c5c',
                                            }}>{item.qcRejected + item.qcApproved + item.fileReceived}</span>
                                        <span
                                            style={{
                                                fontsize: '12px',
                                                fontweight: '600',
                                                fontFamily: 'Poppins',
                                                color: '#a9a9a9',
                                            }}>File Received</span>
                                    </div>
                                    <div class='d-flex flex-column align-items-center'>
                                        <span
                                            style={{
                                                position: 'relative',
                                                Fontsize: '14px',
                                                fontweight: '500',
                                                fontFamily: 'Poppins',
                                                color: '#5c5c5c',
                                            }}>{item.fileReceived}</span>
                                        <span
                                            style={{
                                                fontsize: '12px',
                                                fontweight: '600',
                                                fontFamily: 'Poppins',
                                                color: '#a9a9a9',
                                            }}>Qc Pending</span>
                                    </div>
                                    <div class='d-flex flex-column align-items-center' style={{ backgroundColor: "#D8FFD1" }}>
                                        <span
                                            style={{
                                                position: 'relative',
                                                Fontsize: '14px',
                                                fontweight: '500',
                                                fontFamily: 'Poppins',
                                                color: '#5c5c5c',
                                            }}>{item.qcApproved}</span>
                                        <span
                                            style={{
                                                fontsize: '12px',
                                                fontweight: '600',
                                                fontFamily: 'Poppins',
                                                color: '#a9a9a9',
                                            }}>QC Approved</span>
                                    </div>
                                </div>
                            ))}

                        </>
                    </div>


                </div>

            </div>
            <Toaster errorcode={errorcode} judgmentassingsuccessfully={judgmentassingsuccessfully} />
        </>
    )
};

export default Dashboard