import React, { useContext, useState } from "react";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import CloseIcon from '../../Assets/Images/FormCloseButton.svg'
import EyeIcon from '../../Assets/Images/CloseEyeIcon.svg'
import CopyIcon from '../../Assets/Images/CopyIcon.svg'
import Stack from '@mui/material/Stack';
import { alertClasses } from "@mui/material";
import { UseContext } from "../Context/Context";
import Toaster from "../Toaster/Toaster";
import { CreateNewAdmin, CreateNewQc, GetAllROleForNewAdmin } from "../BaseApi/Baseapi";
import Openeyeicon from '../../Assets/Images/googleye.svg'

const style = {
    position: 'relative',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '40%',
    bgcolor: 'background.paper',
    // height: '95%',
    boxShadow: 24,
    p: 4,
    outline: 'none',

};

const FormStyle = {

    positions: 'absolute',
    width: `100%`,
    margin: '-10px',
    display: 'flex',
    aligItems: 'center',
    justifyContent: 'space-between',
    height: '44px',

};

const FormStyle2 = {
    positions: 'absolute',
    width: `100%`,
    margin: '-10px',
    marginTop: '34px',

}

const Buttunstyle = {
    width: '100%',
    position: 'relative',
    borderRadius: '4px',
    bgcolor: '#fec83b',
    border: '1px #fec83b',
    boxSizing: 'border-box',
    height: '44px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '10px 45px',
    fontSize: '16px',
    color: '#fff',
    fontFamily: 'Poppins',
    '&:hover': {

        backgroundColor: '#fec83b',
    },

}



function NewUserAccessmangment({ props }) {


    const [qcName, setQcName] = useState('');
    const [qcEmail, setQcEmail] = useState('');
    const [qcPassword, setQcPassword] = useState('');
    const [qcConfirmPassword, setQcConfirmPassword] = useState('');
    const [showpssword, setShowpssword] = useState(false);
    const [showconfirmpssword, setShowconfirmpssword] = useState(false);
    const [emailerror, setemailerror] = useState('');
    const [confirm, setConfirm] = useState('');
    const [passworderror, setpasswordError] = useState('');
    const [confirmPasswordcolor, setConfirmPasswordcolor] = useState('');
    const [qccreatesuccessful, setQccreatesuccessful] = useState(false);
    const [error, setError] = useState('');
    const [passwordcolor, setPasswordcolor] = useState('');
    const [copypassword, setCopypassword] = useState('');
    const [newrole, setNewrole] = useState([]);
    const [selectedRole, setSelectedRole] = useState("");
    const [emailError, setEmailError] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [roleError, setRoleError] = useState("");
    const { qcbkName, setQcbkName,
        qcbkRoll, setQcbkRoll,
        newQc, setNewQc, } = useContext(UseContext)






    const HandelEMail = () => {
        if (qcEmail.length >= 0) {
            if (qcEmail == qcEmail.toUpperCase()) {
                setemailerror('Email should be in lower case')
            } else {
                setemailerror('')
            }
        }
    }

    const Handelpassword = () => {
        const hasUpperCase = /[A-Z]/.test(qcPassword);
        const hasLowerCase = /[a-z]/.test(qcPassword);
        const hasNumber = /\d/.test(qcPassword);

        if (qcPassword.length > 15) {
            setpasswordError('weak password');
            setPasswordcolor('red');
        } else if (!hasUpperCase || !hasLowerCase || !hasNumber) {
            setpasswordError('weak password');
            setPasswordcolor('red');
        } else {
            setpasswordError('Strong Password');
            setPasswordcolor('Green');
            // Clear error if all conditions are met
        }
    }


    const handleCopy = () => {
        navigator.clipboard.writeText(qcConfirmPassword)
            .then(() => {
                // Success feedback
                setCopypassword('Copied');
                ;
            })
            .catch(err => {
                // Error feedback
                setCopypassword('Failed to copy');

            });
    };

    //  below function for to Create New Admin Role with specific Access //
    const CreatNewAdminRole = async (e) => {
        e.preventDefault();
        let hasError = false;
    
        // Validate Email
        if (!qcEmail || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(qcEmail)) {
          setEmailError("Please enter a valid email address");
          hasError = true;
        } else {
          setEmailError("");
        }
    
        // Validate Password
        if (!qcPassword) {
          setPasswordError("Password is required");
          hasError = true;
        } else if (qcPassword !== qcConfirmPassword) {
          setPasswordError("Passwords do not match");
          hasError = true;
        } else {
          setPasswordError("");
        }
    
        // Validate Role
        if (!selectedRole) {
          setRoleError("Please select a role");
          hasError = true;
        } else {
          setRoleError("");
        }

        if (!qcName) {
            setError("Name is required");
            hasError = true;
          } else {
            setError("");
          }
    
        // Proceed with API call if no errors
        if (!hasError) {
            const AdminToken=sessionStorage.getItem('AdminToken');
          try {
            const API = await fetch(`${CreateNewAdmin}${selectedRole}`, {
              method: "POST",
              headers: {
                "token": `${AdminToken}`,
                "content-type": "application/json",
              },
              body: JSON.stringify({
                firstName:qcName,
                email: qcEmail,
                password: qcPassword,
                confirmPassword: qcConfirmPassword,
              }),
            });
    
            const Data = await API.json();
             // Display API response
            console.log(Data);
            window.location.reload();
          } catch (error) {
            console.error("Error while creating admin role:", error);
          }
        }
      };

    //  Below Function For To Get New Role For New Admin  //
    const GetNewRoleForAdmin = async () => {
        const AdminToken=sessionStorage.getItem('AdminToken');
        try {
            const API = await fetch(`${GetAllROleForNewAdmin}`, {
                method: "GET",
                headers: {
                    "token": `${AdminToken}`,
                    "content-type": "application/json",
                },
            });

            const Data = await API.json();
            //   Store API responce in Array State //
            setNewrole(Data);
            

        } catch (error) {
            setNewrole([]);
            console.error("Error hitting API:", error);
        }
    };




    return (
        <>

            <Modal
                open={props.open}

                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>

                    <Box sx={FormStyle} >
                        <div>
                            <Typography sx={{
                                fontSize: '24px',
                                fontWeight: '600',
                                fontFamily: 'Poppins',
                                color: '#2c3a52',
                            }}>
                                New User
                            </Typography>
                            <Typography sx={{
                                position: 'relative',
                                fontSize: '14px',
                                fontFamily: 'Poppins',
                                color: '#2c3a52',

                            }}>
                                Setup Credentials
                            </Typography>
                        </div>
                        <div>
                            <img alt="close" height={'15px'} src={CloseIcon} style={{ cursor: 'pointer' }} onClick={props.handleClose}></img>
                        </div>


                    </Box>

                    <Box sx={FormStyle2}>
                        <div>
                            <form onSubmit={CreatNewAdminRole}>
                                <div class="form-group">
                                    <label>Select Role</label>
                                    <select
                                        class="form-select"
                                        aria-label="Default select example"
                                        id="role"
                                        value={selectedRole}
                                        onChange={(e) => setSelectedRole(e.target.value)}
                                        onClick={GetNewRoleForAdmin}>
                                        <option selected>select Role</option>
                                        {newrole.map((item) => <option>{item}</option>)}

                                    </select>
                                </div>
                                {roleError && <p style={{ color: "red" }}>{roleError}</p>}

                                <div class="form-group">
                                    <label for="exampleFormControlInput1"
                                        style={{
                                            fontSize: '20px',
                                            fontWeight: '500',
                                            fontFamily: 'Poppins',
                                            color: '#343434'
                                        }}
                                    >Name</label>
                                    <input type="text" value={qcName} onChange={(e) => { setQcName(e.target.value) }} class="form-control" id="exampleFormControlInput1" placeholder="E.g. John@example.com"
                                        style={{
                                            borderStyle: 'none',
                                            outline: 'none',
                                            lineHeight: '31.39px',
                                            fontWeight: '500',
                                            fontFamily: 'Poppins',
                                            color: '#343434',
                                            fontSize: '16px',
                                            backgroundColor: "#F8F8F8"
                                        }}
                                    ></input>
                                    {error && <p style={{ color: "red" }}>{error}</p>}
                                </div>

                                

                                <div class="form-group">
                                    <label for="exampleFormControlInput1"
                                        style={{
                                            fontSize: '20px',
                                            fontWeight: '500',
                                            fontFamily: 'Poppins',
                                            color: '#343434'
                                        }}
                                    >Email</label>
                                    <input type="email" value={qcEmail} onChange={(e) => { setQcEmail(e.target.value); HandelEMail() }} class="form-control" id="exampleFormControlInput1" placeholder="E.g. John@example.com"
                                        style={{
                                            borderStyle: 'none',
                                            outline: 'none',
                                            lineHeight: '31.39px',
                                            fontWeight: '500',
                                            fontFamily: 'Poppins',
                                            color: '#343434',
                                            fontSize: '16px',
                                            backgroundColor: "#F8F8F8"
                                        }}
                                    ></input>
                                    {emailError && <p style={{ color: "red" }}>{emailError}</p>}
                                </div>

                                <div class="form-group">
                                    <label for="exampleFormControlInput1"
                                        style={{
                                            fontSize: '20px',
                                            fontWeight: '500',
                                            fontFamily: 'Poppins',
                                            color: '#343434'
                                        }}
                                    >Set Password</label>
                                    <div class="form-control d-flex align-items-center" style={{ backgroundColor: "#F8F8F8" }}>
                                        <input type={showpssword == true ? 'text' : "password"} value={qcPassword} onChange={(e) => { setQcPassword(e.target.value); Handelpassword() }} class="form-control" id="exampleFormControlInput1" placeholder="Minimum 8 letters"
                                            style={{
                                                borderStyle: 'none',
                                                outline: 'none',
                                                lineHeight: '31.39px',
                                                fontWeight: '500',
                                                fontFamily: 'Poppins',
                                                color: '#343434',
                                                fontSize: '16px',
                                                backgroundColor: "#F8F8F8"
                                            }}
                                        ></input>
                                        <img alt="icon" height={'24px'} src={showpssword ? Openeyeicon : EyeIcon} onClick={() => setShowpssword(!showpssword)}></img>
                                    </div>
                                    
                                </div>
                                <div style={{
                                    width: '100%',
                                    position: 'relative',
                                    fontSize: '11px',
                                    fontFamily: 'Poppins',
                                    color: '#3e3e3e',
                                }}>
                                    Password must contain: Min 8 Characters, Max 15 Characters, 1 Lowercase, 1 Uppercasse, 1 Number.
                                </div>
                                <div class="form-group">
                                    <label for="exampleFormControlInput1"
                                        style={{
                                            fontSize: '20px',
                                            fontWeight: '500',
                                            fontFamily: 'Poppins',
                                            color: '#343434'

                                        }}
                                    >Confirm Password</label>
                                    <div class="form-control d-flex align-items-center" style={{ backgroundColor: "#F8F8F8" }}>
                                        <input type={showconfirmpssword == true ? 'text' : "password"} value={qcConfirmPassword} onChange={(e) => setQcConfirmPassword(e.target.value)} class="form-control" id="exampleFormControlInput1" placeholder="Re-enter the password"
                                            style={{
                                                borderStyle: 'none',
                                                outline: 'none',
                                                lineHeight: '31.39px',
                                                fontWeight: '500',
                                                fontFamily: 'Poppins',
                                                color: '#343434',
                                                fontSize: '16px',
                                                backgroundColor: "#F8F8F8"
                                            }}
                                        ></input>
                                        <img alt="icon" height={'24px'} src={showconfirmpssword ? Openeyeicon : EyeIcon} onClick={() => setShowconfirmpssword(!showconfirmpssword)}></img>
                                    </div>
                                    {passwordError && <p style={{ color: "red" }}>{passwordError}</p>}
                                </div>
                                <small>{copypassword}</small>
                                <div onClick={handleCopy} style={{
                                    position: 'relative',
                                    display: 'flex',
                                    justifyContent: "flex-end",
                                    gap: '10px',
                                    cursor: 'pointer',
                                }}>

                                    <img src={CopyIcon} alt="icon" height={'24px'}></img>
                                    <span
                                        style={{
                                            position: 'relative',
                                            fontSize: '16px',
                                            lineHeight: '31.39px',
                                            fontFamily: 'Poppins',
                                            color: '#343434',
                                        }}
                                    >Copy Password</span>
                                </div>

                                <Stack direction={'row'}>
                                    <Button sx={Buttunstyle} type="submit">Confirm</Button>
                                </Stack>
                            </form>
                        </div>
                    </Box>
                </Box >
            </Modal >

        </>
    )
};

export default NewUserAccessmangment