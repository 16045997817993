import React, { useContext, useEffect, useState } from "react";
import Adminstyle from '../Adminteam/Admin.module.css'
import SideBar from "../Sidebar/SIdebar";
import Icon1 from '../../Assets/Images/DashboardIcon1.svg'
import Profile from '../../Assets/Images/Profilephoto1.png'
import NewUser from "../NewUser/NewUser";
import { UseContext } from "../Context/Context";
import Dropdown from 'react-bootstrap/Dropdown';
import RightArrow from '../../Assets/Images/RightArrow.svg'
import AssingButton from '../../Assets/Images/AssingInterButtun.svg'
import { styled } from "@mui/material";
import { AdminAllmemebarSearchApi, AdminQcsearch, GeTAllMembers, GetAllQcWorks, GetAllValume, GetInternListByQcId, GetQcDropdown, GetQclist, GetTotalCountCAR, Getvalumepartnumber, PdfStartIndex } from "../BaseApi/Baseapi";
import { PdfFileSAssingJudgement } from '../BaseApi/Baseapi'
import { InternCreateJUdgement } from '../BaseApi/Baseapi'
import Toaster from "../Toaster/Toaster";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import index from "react-highlight-words";
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import Resetpassword from '../../Assets/Images/Forgottpassword.png'
import { useNavigate } from "react-router-dom";
import ForgotPassword from "../Forgotpassword/Forgotpassword";




function AdminTeam(props) {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const { qcbkName, newQc } = useContext(UseContext);
    const [qclist, setQclist] = useState([]);
    console.log('qcbkName', qcbkName);
    const [isclick, setiSclick] = useState(true);
    const [showDropdown, setShowDropdown] = useState(false);
    const [selectQcdrop, setSelectQcDrop] = useState('');
    const [hoveredItem, setHoveredItem] = useState(null);
    const [qcId, setQcId] = useState('');
    const [getInter, setGetIntern] = useState([]);
    const [internID, setInternId] = useState('');
    const [judgementqcid, setJudgementQcid] = useState('');
    const [totalassingjudgmentnum, settotalassingjudgmentnum] = useState(0);
    const [totalfilecompletednum, settotalfilecompletednum] = useState(0);
    const [totalqcapprovednum, settotalqcapprovednum] = useState(0);
    const [totalqcrejectnum, settotalqcrejectnum] = useState(0);

    const [yearselect, setYear] = useState('');
    const [valume, setValume] = useState([]);
    const [partNo, setPartNo] = useState([]);
    const [judgementNo, setJudgementNo] = useState('');
    const [valumenum, setvalumenum] = useState('');
    const [selectpartNum, setSelectPartNum] = useState('');
    const [pdfstartindex, setPdfstartIndex] = useState('');
    const [pdfEndindex, setPdfEndIndex] = useState('');
    const [databasepdfsize, setDatabasepdfSize] = useState([]);
    const [getallqcwork, setGetallQcwork] = useState([]);
    const [gettotalassingjudgementQc, setGetTotalAssingJudgementQc] = useState([]);
    const [receivedfilenum, setReceivedFileNum] = useState([]);
    const [approvedfilenum, setApprovedFileNum] = useState([]);
    const [rejectfilenum, setRejectFileNum] = useState([]);
    const [getallmemebar, setGetallmemebar] = useState([]);
    const [qcDropdownlist, setQcdropdown] = useState([]);
    const [judegmentcreatesuccessfully, setjudegmentcreatesuccessfully] = useState(false);
    const [judegmentfull, setjudegmentfull] = useState(false);
    const [buttoncolor, setbuttoncolor] = useState(2);
    const [searchallmemebar, setsearchallmemebar] = useState('');
    const [searchQc, setSearchQc] = useState('');
    const Navigated = useNavigate();


    const HandelQcList = async () => {
        const AdminToken=sessionStorage.getItem('AdminToken');
        try {
            const adminId = sessionStorage.getItem('AdminId')
            const Data = await fetch(`${GetQclist}`,{
                method: "GET",
                headers: {
                    'token': `${AdminToken}`,
                    'content-type': 'application/json'
                }
            })
            var ResponceList = await Data.json();
            setQclist(ResponceList);


        } catch (error) {
            setQclist([]);
        }



    };

    const HandelQcDropdownlist = async () => {
        const AdminToken=sessionStorage.getItem('AdminToken')
        try {
            const adminId = sessionStorage.getItem('AdminId')
            const Data = await fetch(`${GetQcDropdown}`,{
                method: "GET",
                headers: {
                    'token': `${AdminToken}`,
                    'content-type': 'application/json',
                }
            })
            var ResponceList = await Data.json();
            setQcdropdown(ResponceList);


        } catch (error) {
        }



    };
    const HandelInternList = async () => {
        const AdminToken=sessionStorage.getItem('AdminToken');
        try {
            const Data = await fetch(`${GetInternListByQcId}${qcId}`,{
                method: "GET",
                headers: {
                    'token': `${AdminToken}`,
                    'content-type': 'application/json'
                }
            })
            var Responce = await Data.json();
            if (Responce.status === 404) {

                setGetIntern([]);
            } else {
                setGetIntern(Responce);
            }

        } catch (error) {

        }
    }

    useEffect(() => {
        if (qcId !== '') {
            HandelInternList();
        };

    }, [qcId]);


    useEffect(() => {

        // HandelQcDropdownlist();


    }, []);
    useEffect(() => {
        if (searchallmemebar == '') {
            setQclist([]);
            HandelQcList();
        }
    }, [searchallmemebar])
    const SelectQc = (qcName) => {
        setSelectQcDrop(qcName)
    }
    console.log('selectQcdrop', selectQcdrop, selectQcdrop.length)
    const toggleDropdown = () => setShowDropdown(!showDropdown);
    useEffect(() => {
        if (selectQcdrop != '') {
            setShowDropdown(false);
        }
    }, [selectQcdrop])

    const handleMouseEnter = (item) => {
        setHoveredItem(item.qcName);
    };

    const handleMouseLeave = () => {
        setHoveredItem(null);
    };

    const GetQcId = (ID) => {
        setQcId(ID);

    };

    console.log('qcId', qcId)


    const GetAllValumes = async () => {
        const AdminToken=sessionStorage.getItem('AdminToken');
        try {
            const formData = { year: yearselect }
            const queryParams = new URLSearchParams(formData).toString();
            const Data = await fetch(`${GetAllValume}${queryParams}`, {
                method: 'GET',
                headers: {
                    'token': `${AdminToken}`,
                    'content-type': 'application/json'
                }
            })

            const Responce = await Data.json();
            setValume(Responce);
            console.log(Responce)

        } catch (error) {
            setValume([]);
        }
    };

    useEffect(() => {
        if (yearselect.length == 4) {
            GetAllValumes();
        }
    }, [yearselect])


    const startYear = 1950;
    const endYear = new Date().getFullYear();
    const years = [];

    for (let i = endYear; i >= startYear; i--) {
        years.push(i);
    };
    years.sort((a, b) => a - b);
    valume.sort((a, b) => a - b);

    const GetPartNumber = async () => {
        const AdminToken=sessionStorage.getItem('AdminToken');
        try {
            const formData = {
                year: yearselect,
                volume: valumenum,
            };


            const queryParams = new URLSearchParams(formData).toString();

            const response = await fetch(`${Getvalumepartnumber}${queryParams}`, {
                method: 'GET',
                headers: {
                    'token': `${AdminToken}`,
                    'content-type': 'application/json'
                }
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const result = await response.json();
            setPartNo(result);
            console.log('partNo', result);
        } catch (error) {
            setPartNo([]);
        }
    };

    useEffect(() => {
        if (valumenum.length > 0) {
            GetPartNumber();
        }
    }, [valumenum])

    const CreateJudgement = async () => {
        const AdminToken=sessionStorage.getItem('AdminToken');
        if (selectQcdrop != '' && yearselect != '' && valumenum != '' || selectpartNum != '' && pdfEndindex != '') {



            try {


                const formData = new FormData();

                if (selectpartNum == '') {
                    formData.append('year', yearselect);
                    formData.append('volume', valumenum);
                    formData.append('startLimit', pdfstartindex);
                    formData.append('endLimit', pdfEndindex);
                } else {
                    formData.append('year', yearselect);
                    formData.append('volume', valumenum);
                    formData.append('part', selectpartNum);
                    formData.append('startLimit', pdfstartindex);
                    formData.append('endLimit', pdfEndindex);
                }

                console.log("Sending formData:", formData);


                const Data = await fetch(`${InternCreateJUdgement}${qcId}/${internID}`, {
                    method: "POST",
                    headers: {
                        'token': `${AdminToken}`,
                        'content-type': 'application/json'
                    },
                    body: formData,
                });

                if (!Data.ok) {
                    const errorText = await Data.text();
                    console.error("Error response from server:", errorText);
                    throw new Error(`Error ${Data.status}: ${errorText}`);
                } else {
                    setjudegmentcreatesuccessfully(true);

                    setTimeout(() => {
                        // window.location.reload();
                    }, 4000);

                }

                const Response = await Data.json();
                console.log(Response);





            } catch (error) {
                console.error("Request failed:", error);
                // alert(`Request failed: ${error.message}`);
            }
        }
    };


    console.log(internID, judgementqcid, qcId);

    const GetPdgfirstIndex = async () => {
        const AdminToken=sessionStorage.getItem('AdminToken');
        try {
            const Api1 = `${PdfStartIndex}year=${yearselect}&volume=${valumenum}&part=${selectpartNum}`;
            const Api2 = `${PdfStartIndex}year=${yearselect}&volume=${valumenum}`;
            const Data = await fetch(`${selectpartNum == '' ? Api2 : Api1}`, {
                method: "GET",
                headers: {
                    'token': `${AdminToken}`,
                    'content-type': 'application/json'
                }
            })
            const Responce = await Data.json();
            setPdfstartIndex(Responce)
        } catch (error) {
            alert(error)
        }
    }

    useEffect(() => {
        if (yearselect.length > 0 && valumenum.length > 0 || selectpartNum.length > 0) {
            GetPdgfirstIndex();
        }
    }, [yearselect, valumenum, selectpartNum]);

    console.log(selectpartNum);
    const GetPdfFileAllSize = async () => {
        const AdminToken=sessionStorage.getItem('AdminToken');
        try {
            const Api1 = `${PdfFileSAssingJudgement}year=${yearselect}&volume=${valumenum}&part=${selectpartNum}`;
            const Api2 = `${PdfFileSAssingJudgement}year=${yearselect}&volume=${valumenum}`
            const Data = await fetch(`${selectpartNum == '' ? Api2 : Api1}`, {
                method: "GET",
                headers: {
                    'token': `${AdminToken}`,
                    'content-type': 'application/json'
                }
            });

            if (!Data.ok) {
                throw new Error(`HTTP error! status: ${Data.status}`);
            }

            const Responce = await Data.json();
            // alert(JSON.stringify(Responce));  

            let tempArray = [];
            let startIndex = pdfstartindex === 0 ? 1 : pdfstartindex;
            // console.log('tempArray', pdfstartindex);
            for (var i = pdfstartindex; i <= Responce; i++) {

                tempArray.push(i);


            }

            setDatabasepdfSize(tempArray.length > 0 ? tempArray : []);
            if (tempArray.length == 0) {
                setjudegmentfull(true);
                setTimeout(() => {
                    setjudegmentfull(false);
                }, [2000]);


            } else {
                setjudegmentfull(false);
            }
            console.log('tempArray', tempArray, databasepdfsize, judegmentfull);





        } catch (error) {
            // alert(`Error: ${error.message}`);
        }
    };



    const GetallQcworks = async () => {
        const AdminToken=sessionStorage.getItem('AdminToken');
        try {
            const Data = await fetch(`${GetAllQcWorks}`, {
                method: 'GET',
                headers: {
                    'token': `${AdminToken}`,
                    'content-type': "application/json"
                }
            })
            const Responce = await Data.json();
            console.log('Responce', Responce);
            setGetallQcwork(Responce);


        } catch (error) {
            // alert(error)
        }
    }

    useEffect(() => {
        if (searchQc == '') {
            setGetallQcwork([]);
            GetallQcworks();
        }

    }, [searchQc]);

   


    const Getallmemebar = async () => {

        try {
            const Data = await fetch(`${GeTAllMembers}`, {
                method: 'GET',
                headers: {
                    'content-type': "application/json"
                }
            })
            const Responce = await Data.json();
            setGetallmemebar(Responce);
            console.log('Responce', Responce);
        } catch (error) {
            // alert(error)
        }
    };

    const SearchAllmemebaredata = async () => {
        setQclist([]);
        const AdminToken=sessionStorage.getItem('AdminToken');
        try {
            const Data = await fetch(`${AdminAllmemebarSearchApi}${searchallmemebar}`,{
                method: 'GET',
                headers: {
                    'token': `${AdminToken}`,
                    'content-type': "application/json"
                },

            });
            const Responce = await Data.json();
            setQclist(Responce)
        } catch (error) {
            setQclist([]);
            console.log(error)
        }
    };

    const SearchQc = async () => {
        setGetallQcwork([]);
        const AdminToken=sessionStorage.getItem('AdminToken');
        try {
            const Data = await fetch(`${AdminQcsearch}${searchQc}`, {
                method: 'GET',
                headers: {
                    'token': `${AdminToken}`,
                    'content-type': "application/json"
                }
            })
            const Responce = await Data.json();
            setGetallQcwork(Responce);
        } catch (error) {

            setGetallQcwork([]);
        }
    }

    // useEffect(()=>{
    //     if(searchQc){
    //         SearchQc();
    //     }
    // },[searchQc])

    // handel Click Event for navigate to re-set password page
    const forgotpassword = () => {
        Navigated('/resetpassword')
    };


    // Store QcEmail or Role and Intern Email or Role in Session storage to reset password
    const HandelstoreEmailorId = (Email, Role) => {
        sessionStorage.setItem('Reset-Email', Email);
        sessionStorage.setItem('Reset-Role', Role);
    };



          const GetCAR = async () => {
            const AdminToken=sessionStorage.getItem('AdminToken');
                  try {
                      const Data = await fetch(`${GetTotalCountCAR}`, {
                          method: "GET",
                          headers: {
                              'token': `${AdminToken}`,
                              'content-type': 'application/json'
                          }
                      })
                      const Responce = await Data.json();
                      settotalassingjudgmentnum(Responce?.totalJudgementSum);
                      settotalqcapprovednum(Responce?.approvedCount);
                      settotalfilecompletednum(Responce?.completedCount);
                      settotalqcrejectnum(Responce?.rejectedCount);
          
                  } catch (error) {
                      console.log(error);
                  }
              };
          
              useEffect(() => {
                  // GetAssingJudgment();
                  GetCAR();
              }, []);

    return (
        <>
            <div className={Adminstyle.MainDIv}>
                <div style={{
                    position: 'relative',
                    height: '100vh',

                }}>
                    <SideBar buttoncolor={buttoncolor} />
                </div>
                <div className={Adminstyle.Adminteam}>
                    <div className={Adminstyle.Div1}>
                        <div className={Adminstyle.Div1text} class='p-2 d-flex justify-content-between'>
                            <span>Assign Judgement</span>
                            <span class='text-danger' style={{ display: judegmentfull == true ? '' : "none" }}>All Judgement already Assigned</span>
                        </div>
                        <div className={Adminstyle.Div2} >
                            <div>

                                <div class='p-2' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around', width: '100%', gap: '20px' }}>
                                    <div style={{ width: '200px' }}  >
                                        <span className={Adminstyle.Div2text}>Select </span>
                                        <Dropdown show={showDropdown} onToggle={() => { HandelQcDropdownlist(); toggleDropdown(); }}>
                                            <Dropdown.Toggle
                                                className={Adminstyle.Div2Box}
                                                style={{ width: '100%' }}
                                                variant="white"
                                                id="dropdown-basic"
                                                onClick={() => { HandelQcDropdownlist(); toggleDropdown(); }}
                                            >
                                                {selectQcdrop.length === 0 ? (
                                                    <span>Select Intern</span>
                                                ) : (
                                                    <span>{selectQcdrop}</span>
                                                )}
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu
                                                className={Adminstyle.dropdown}
                                                style={{ display: showDropdown ? 'block' : 'none', width: "300px", }}
                                            >
                                                {qcDropdownlist.map((item, index) => (
                                                    <div key={index} style={{ position: 'relative', borderBottom: '1px solid #f5f5f5', }}>
                                                        <Dropdown.Item


                                                            onMouseEnter={() => handleMouseEnter(item)}

                                                            onMouseMove={() => GetQcId(item.qcId)}


                                                        >
                                                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', width: '100%', gap: '10px', }}>
                                                                <div style={{ width: '50%', position: 'relative', display: 'flex', alignItems: 'center', flexDirection: 'row', left: '-10px', gap: "5px", padding: "10px", paddingTop: "10px" }}>
                                                                    <img
                                                                        src={Profile}
                                                                        alt="profile"
                                                                        style={{ height: '24px', borderRadius: '50%' }}
                                                                    />
                                                                    <span>{item.qcName}</span>
                                                                    <span>{`(${item.roleName})`}</span>

                                                                </div>


                                                                <div style={{ width: '50%', display: 'flex', alignItems: 'center', justifyContent: 'End', flexDirection: 'row' }}>
                                                                    <img src={RightArrow} height={'24px'}></img>

                                                                </div>
                                                            </div>





                                                        </Dropdown.Item>



                                                        {hoveredItem === item.qcName && (
                                                            <div


                                                            >
                                                                <div style={{ position: 'absolute', left: '300px', backgroundColor: '#fff' }} className={Adminstyle.dropdown2} >
                                                                    {getInter.map((item, index) => (
                                                                        <>
                                                                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '300px', gap: '10px', padding: '10px', cursor: 'pointer', borderBottom: '1px solid #f5f5f5', height: "55px", }}>
                                                                                <img src={Profile} style={{ height: '24px ', borderRadius: "50%" }} ></img>
                                                                                <span key={index} onClick={() => { SelectQc(item.internName); setInternId(item.internId); setJudgementQcid(item.qcId); }}>{item.internName}</span>
                                                                            </div>

                                                                        </>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>

                                                ))}
                                            </Dropdown.Menu>
                                        </Dropdown>


                                    </div>
                                    <div style={{ width: '200px ' }} >
                                        <span className={Adminstyle.Div2text}>Select Year :</span>
                                        <Dropdown onClick={() => { setvalumenum(''); setSelectPartNum(''); setPdfstartIndex('') }}>

                                            <Dropdown.Toggle
                                                className={Adminstyle.Div2Box}
                                                style={{ width: '100%' }}
                                                variant="white"
                                                id="dropdown-basic"


                                            >
                                                {yearselect.length === 0 ? (
                                                    <span>Select year</span>
                                                ) : (
                                                    <span >{yearselect}</span>
                                                )}
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu style={{ height: "250px", overflowY: 'scroll', width: '100%' }}>
                                                {years.map((year) => (
                                                    <Dropdown.Item key={year} onClick={(e) => { setYear(e.target.innerText) }} >
                                                        {year}
                                                    </Dropdown.Item>
                                                ))}
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                    <div style={{ width: '200px' }} >
                                        <span className={Adminstyle.Div2text}>Select Volume No :</span>

                                        <Dropdown onClick={() => { setSelectPartNum(''); setPdfstartIndex('') }}>

                                            <Dropdown.Toggle
                                                className={Adminstyle.Div2Box}
                                                style={{ width: '100%' }}
                                                variant="white"
                                                id="dropdown-basic"

                                            >
                                                {valumenum.length === 0 ? (
                                                    <span>Select Volume</span>
                                                ) : (
                                                    <span >{valumenum}</span>
                                                )}
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu style={{ height: valume.length > 3 ? "30vh" : "fit-content", overflowY: 'scroll', width: '100%' }}>
                                                {valume.map((valume) => (
                                                    <Dropdown.Item key={valume} onClick={(e) => { setvalumenum(e.target.innerText); }} >
                                                        {valume}
                                                    </Dropdown.Item>
                                                ))}
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                    <div style={{ width: '200px' }}>
                                        <span className={Adminstyle.Div2text}>Select Part No(If appli.): </span>

                                        <Dropdown>

                                            <Dropdown.Toggle
                                                className={Adminstyle.Div2Box}
                                                style={{ width: '100%' }}
                                                variant="white"
                                                id="dropdown-basic"

                                            >
                                                <span>{selectpartNum.length === 0 ? 'Select Part' : `${selectpartNum}`}</span>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu style={{ height: selectpartNum.length > 3 ? "30vh" : "fit-content", overflowY: 'scroll', width: '100%' }}>
                                                {partNo.map((partNo) => (
                                                    <Dropdown.Item key={partNo} onClick={(e) => setSelectPartNum(e.target.innerText)} >
                                                        {partNo}
                                                    </Dropdown.Item>
                                                ))}
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                    <div style={{ width: '200px', }} >
                                        <span className={Adminstyle.Div2text}>Select Judgement Range:</span>
                                        <div style={{ position: "relative", display: "flex", alignItems: "center", justifyContent: "center", gap: "10px" }}>
                                            <div className={Adminstyle.Div2Box} style={{ width: "60px", display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
                                                <input readOnly style={{ borderStyle: 'none', outline: 'none', width: "40px", textAlign: "center", barckgroundColor: "transparent", position: 'relative' }} className={Adminstyle.Div2Boxtext} placeholder={pdfstartindex == 0 ? '0' : pdfstartindex}></input>

                                            </div>
                                            <Dropdown onToggle={() => GetPdfFileAllSize()}>

                                                <Dropdown.Toggle
                                                    className={Adminstyle.Div2Box}
                                                    style={{ width: '120px' }}
                                                    variant="white"
                                                    id="dropdown-basic"


                                                >
                                                    {pdfEndindex.length === 0 ? (
                                                        <span onClick={() => GetPdfFileAllSize()}>Select End</span>
                                                    ) : (
                                                        <span >{pdfEndindex}</span>
                                                    )}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu style={{ height: databasepdfsize.length < 3 ? "fit-content" : "30vh", overflowY: 'scroll', width: '90px' }}>
                                                    {databasepdfsize.map((item, index) => (
                                                        <Dropdown.Item key={index} onClick={() => setPdfEndIndex(item)} >
                                                            {item}
                                                        </Dropdown.Item>
                                                    ))}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </div>
                                    <div onClick={CreateJudgement} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '40px', width: '40px', marginTop: '20px' }}>
                                        <img src={AssingButton} style={{ height: '40px' }}></img>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>



                    <div className={Adminstyle.TableDiv}>
                        <div  >
                            <div class="row d-flex p-2" >
                                <div class="col-6 d-flex  flex-row align-items-center  gap-3 ">
                                    <div className={isclick == true ? Adminstyle.TableDiv1 : ""} onClick={() => { setiSclick(true); setsearchallmemebar(''); }} style={{ cursor: 'pointer' }}>
                                        My QCs
                                    </div>
                                    <div className={isclick == false ? Adminstyle.TableDiv1 : ''} onClick={() => { setiSclick(false); setsearchallmemebar(''); HandelQcList() }} style={{ cursor: 'pointer' }} >
                                        All Members
                                    </div>
                                </div>
                                <div class="col-6 d-flex  flex-row align-items-center gap-2 justify-content-between">
                                    <div class='col-8 ' className={Adminstyle.TableSearchbox}>
                                        <input class='col-8' style={{
                                            borderStyle: 'none',
                                            outline: 'none',
                                            fontSize: '12px',
                                            fontFamily: 'Poppins',
                                            color: '#5c5c5c',


                                        }} placeholder="Search" value={isclick == true ? searchQc : searchallmemebar} onChange={isclick == true ? (e) => { setSearchQc(e.target.value) } : (e) => { setsearchallmemebar(e.target.value) }} onKeyDown={(e) => { if (e.key == 'Enter') { isclick == true ? SearchQc() : SearchAllmemebaredata() } }} ></input>
                                        {isclick == true ?
                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '40px', width: '40px', cursor: 'pointer' }} onClick={SearchQc} ><FontAwesomeIcon icon={faMagnifyingGlass} /></div>
                                            : <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '40px', width: '40px', cursor: 'pointer' }} onClick={SearchAllmemebaredata}><FontAwesomeIcon icon={faMagnifyingGlass} /></div>}
                                    </div>
                                    <div class='col-3' >
                                        <button className={Adminstyle.TableAddQc} style={{ cursor: 'pointer', width: "130px" }} onClick={handleOpen}>Add New QC</button>
                                    </div>
                                </div>
                            </div>

                        </div>
                        {getallqcwork == 0 && isclick == true ? <Box sx={{ width: '100%' }}>
                            <LinearProgress sx={{
                                '& .MuiLinearProgress-bar': {
                                    backgroundColor: '#fec83b',

                                },
                                backgroundColor: 'transparent',
                            }} />
                        </Box> :
                            <table style={{ display: isclick == true ? '' : 'none', width: '100%' }} class="table table-bordered table-hover">

                                <thead className={Adminstyle.TableHeader} style={{ position: 'sticky', top: '0', backgroundColor: '#fff' }}>
                                    <tr  >
                                        <th >Sr.no</th>
                                        <th  >QC Name</th>


                                        <th>Interns</th>
                                        <th >Total Assigned Judgement</th>
                                        <th class="text-warning">Qc Pending</th>
                                        <th style={{ color: "#223a48", backgroundColor: '#fff7d8' }} >QC Approved</th>
                                        <th class="text-danger">QC Rejected</th>
                                        <th class='text-success'>File Submitted</th>


                                    </tr>

                                </thead >
                                <tbody className={Adminstyle.TableHeader} >
                                    {getallqcwork?.map((item, index) => (
                                        <React.Fragment key={index}>
                                            <tr>
                                                <td>{index + 1}</td>
                                                <td className="col-3 d-flex w-100 gap-2 align-items-center">
                                                    <img
                                                        alt="profile"
                                                        height="24px"
                                                        src={Profile}
                                                        style={{ borderRadius: '50%' }}
                                                    />
                                                    <span>{item.qcName}</span>
                                                </td>
                                                <td  >
                                                    {item.totalInternCount} Interns

                                                </td>
                                                <td>
                                                    {item.judgmentNum}</td>
                                                <td>
                                                    {item.fileReceivedCount}

                                                </td>
                                                <td style={{ color: "#223a48", backgroundColor: '#fff7d8' }}>{item.fileApprovedCount}</td>
                                                <td>{item.fileRejectedCount}</td>
                                                <td>{item.fileReceivedCount + item.fileApprovedCount + item.fileRejectedCount}</td>

                                            </tr>
                                        </React.Fragment>
                                    ))}
                                    <tr style={{ fontSize: "15px", color: "#424242", fontFamily: "Poppins", borderTop: "2px solid #424242", position: "sticky", bottom: "0px", backgroundColor: "white" }}>
                                        <td class='text-dark'>Total:</td>
                                        <td class='text-dark'>{getallqcwork?.length}</td>

                                        <td class='text-dark'>{getallqcwork?.reduce((total, item) => total + item.totalInternCount, 0)}</td>

                                        <td class='text-dark'>{totalassingjudgmentnum}</td>

                                        <td class='text-dark'>{totalfilecompletednum}</td>
                                        <td class='text-dark'>{totalqcapprovednum}</td>
                                        <td class='text-dark'>{totalqcrejectnum}</td>
                                        <td class='text-dark'>{totalfilecompletednum+totalqcapprovednum+totalqcrejectnum}</td>


                                    </tr>

                                </tbody>


                            </table>}

                        {/* all memebare */}
                        {isclick == false && qclist.length == 0 ? <Box sx={{ width: '100%' }}>
                            <LinearProgress sx={{
                                '& .MuiLinearProgress-bar': {
                                    backgroundColor: '#fec83b',

                                },
                                backgroundColor: 'transparent',
                            }} />
                        </Box> : <table style={{ display: isclick == false ? '' : 'none', width: '100%' }} class="table table-bordered  ">

                            <thead style={{ fontSize: "12px", color: "#424242", fontFamily: "Poppins", position: 'sticky', top: '0', backgroundColor: '#fff', }}>
                                <tr  >
                                    <th >Sr.no</th>
                                    <th  >QC Name</th>


                                    <th>Intern Name</th>
                                    <th >Assigned Year</th>
                                    <th>Assigned Volume</th>
                                    <th >Part</th>
                                    <th >No. of Judgement</th>
                                    <th class="text-warning">Qc Pending</th>

                                    <th style={{ color: "#223a48", backgroundColor: '#fff7d8' }}>QC Approved</th>
                                    <th style={{ color: '#ff0000' }}>QC Rejected</th>
                                    <th class='text-success'>File Submitted</th>


                                </tr>

                            </thead >




                            <tbody style={{ fontSize: "12px", color: "#424242", fontFamily: "Poppins", }}>
                                {qclist?.map((qc, qcIndex) => (
                                    <React.Fragment key={qcIndex}>
                                        {qc.interns.map((intern, internIndex) => (
                                            <tr key={internIndex}>
                                                {internIndex === 0 && (
                                                    <React.Fragment>
                                                        <td rowSpan={qc.interns.length}>{qcIndex + 1}</td>
                                                        <td rowSpan={qc.interns.length}>
                                                            <div class='d-flex justify-content-between align-items-center'>
                                                                <span>  {qc.qcName}</span>
                                                                <img alt="profile" height="24px" src={Resetpassword} style={{ borderRadius: '50%', cursor: 'pointer' }} onClick={() => { forgotpassword(); HandelstoreEmailorId(qc.email, qc.roleName) }}></img>
                                                            </div> </td>
                                                    </React.Fragment>
                                                )}
                                                {/* Only display the intern's name on the first row for that intern */}
                                                {qc.interns.findIndex(i => i.internName === intern.internName) === internIndex && (
                                                    <td rowSpan={qc.interns.filter(i => i.internName === intern.internName).length}>
                                                        <div class='d-flex justify-content-between align-items-center'>
                                                            <span>  {intern.internName}</span>
                                                            <img alt="profile" height="24px" src={Resetpassword} style={{ borderRadius: '50%', cursor: 'pointer' }} onClick={() => { forgotpassword(); HandelstoreEmailorId(intern.email, intern.roleName) }}></img>
                                                        </div>
                                                    </td>
                                                )}
                                                <td>{intern.assignedYear}</td>
                                                <td>{intern.assignedVolume}</td>
                                                <td>{intern.part}</td>
                                                <td>{intern.noOfJudgement}</td>
                                                <td>{intern.fileReceived}</td>
                                                <td style={{ color: "#223a48", backgroundColor: '#fff7d8' }}>{intern.qcApproved}</td>
                                                <td>{intern.qcRejected}</td>
                                                <td>{intern.fileReceived + intern.qcApproved + intern.qcRejected}</td>
                                            </tr>
                                        ))}
                                    </React.Fragment>
                                ))}
                                <tr style={{ fontSize: "15px", color: "#424242", fontFamily: "Poppins", borderTop: "2px solid #424242", position: "sticky", bottom: "0px", backgroundColor: "white" }} >
                                    <td class='text-dark font-weight-bold'>Total:</td>

                                    <td colSpan={5}></td>
                                    <td class='text-dark'>{gettotalassingjudgementQc.reduce((a, b) => a + b, 0)}</td>

                                    <td class='text-dark'>{receivedfilenum.reduce((a, b) => a + b, 0)}</td>
                                    <td class='text-dark'>{approvedfilenum.reduce((a, b) => a + b, 0)}</td>
                                    <td class='text-dark'>{rejectfilenum.reduce((a, b) => a + b, 0)}</td>
                                    <td class='text-dark'>{receivedfilenum.reduce((a, b) => a + b, 0) + approvedfilenum.reduce((a, b) => a + b, 0) + rejectfilenum.reduce((a, b) => a + b, 0)}</td>


                                </tr>

                            </tbody>


                        </table>}

                    </div>
                </div>

                <NewUser props={{ open, handleClose, setOpen }} />

            </div >

            <Toaster judegmentcreatesuccessfully={judegmentcreatesuccessfully} />

        </>
    )
};

export default AdminTeam;