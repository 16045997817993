import React, { useContext, useState } from "react";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import CloseIcon from '../../../Assets/Images/FormCloseButton.svg'
import EyeIcon from '../../../Assets/Images/CloseEyeIcon.svg'
import CopyIcon from '../../../Assets/Images/CopyIcon.svg'
import Stack from '@mui/material/Stack';
import Toaster from "../../Toaster/Toaster";
import { CreateNewIntern } from "../../BaseApi/Baseapi";
import openeye from '../../../Assets/Images/googleye.svg'

const style = {
    position: 'relative',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '40%',
    bgcolor: 'background.paper',
    // height: '95%',
    boxShadow: 24,
    p: 4,
    outline: 'none',

};

const FormStyle = {

    positions: 'absolute',
    width: `100%`,
    margin: '-10px',
    display: 'flex',
    aligItems: 'center',
    justifyContent: 'space-between',

};

const FormStyle2 = {
    positions: 'absolute',
    width: `100%`,
    margin: '-10px',
    marginTop: '34px',

}

const Buttunstyle = {
    width: '100%',
    position: 'relative',
    borderRadius: '4px',
    bgcolor: '#fec83b',
    border: '1px #fec83b',
    boxSizing: 'border-box',
    height: '44px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '10px 45px',
    fontSize: '16px',
    color: '#fff',
    fontFamily: 'Poppins',
    '&:hover': {

        backgroundColor: '#fec83b',
    },

}



function NewIntern({ props }) {
    
    const [Name, setName] = useState('');
    const [Email, setEmail] = useState('');
    const [Password, setPassword] = useState('');
    const [ConfirmPassword, setConfirmPassword] = useState('');
    const [showpssword, setShowpssword] = useState(false);
    const [showconfirmpssword, setShowconfirmpssword] = useState(false);
    const [emailerror,setemailerror]=useState('');
    const [confirm,setconfirm]=useState('');
    const [passworderror,setpasswordError]=useState('');
    const [interncreatesuccessfully,setInterncreatesuccessfully]=useState(false);
    const [error, setError] = useState('');
    const [passwordcolor, setPasswordcolor] = useState('');
    const [confirmpasswordcolor, setConfirmPasswordcolor] = useState('');
    const [copypassword, setCopypassword] = useState('');


    const HandelCreateIntern = async (e) => {
        e.preventDefault()
       
        const bodydata = {
            internName: Name,
            internEmail: Email,
            internPassword: Password,
            internConfirmPassword: ConfirmPassword,
        }

        const nameParts = Name.trim().split(' ');
        if(Password!=ConfirmPassword){
            setconfirm('Password does not match');
            setConfirmPasswordcolor('red');
        }else{
            setconfirm('Password match');
            setConfirmPasswordcolor('green');
            if(Name==''||Email==''||Password==''||ConfirmPassword==''){

        
              if(Email==''){
                setemailerror('Please enter Email');
              }else{
                setemailerror('');
              };

              if(Password==''){
                setPasswordcolor('red');
                setpasswordError('Please enter Password');
              }else{
                setpasswordError('');
              }
              if(ConfirmPassword==''){
                setConfirmPasswordcolor('red');
                setconfirm('Please enter ConfirmPassword');
              }else{
                setpasswordError('');
              }


        if (nameParts.length < 2) {
            setError('Please enter first name and last name.');
        } else if (nameParts.length > 2) {
            setError(' first name and last name are allowed.');
        } else {
            setError('');
            const [firstName, lastName] = nameParts;
            // Proceed with form submission or other logic
            console.log('Form submitted:', { firstName, lastName });
        };

        
    }else{    
         if(nameParts.length==2){
             
         
        try {

           
            const QcToken=sessionStorage.getItem('QcToken');
            const QcId=sessionStorage.getItem('QcID')
            const Data = await fetch(`${CreateNewIntern}${QcId}`, {
             method:"POST",
             headers:{
                'token':`${QcToken}`,
                'Content-Type':'application/json',
             },
             body:JSON.stringify(bodydata)
            })
            const Responce=await Data.json();
            props.setOpen(false);
            setInterncreatesuccessfully(true);
            console.log(Responce);
            setTimeout(()=>{
                window.location.reload();
            },4000)
        
        }
     catch (error) {
        //   alert(error)
        }
    }else{
        setError('Please enter first name and last name.');
        
    }
    }
    }
}
    



    const HandelEmail=()=>{
        if(Email.length>=0){
            if(Email!=Email.toLowerCase()){
                setemailerror('Email Should be in lower case')
            }else{
                setemailerror('');
            }

        
        }
    };

    const Handelpassword = () => {
            const hasUpperCase = /[A-Z]/.test(Password);
            const hasLowerCase = /[a-z]/.test(Password);
            const hasNumber = /\d/.test(Password);
        
            if (Password.length > 15) {
                setpasswordError('weak password');
                setPasswordcolor('red');
            } else if (!hasUpperCase || !hasLowerCase || !hasNumber) {
                setpasswordError('weak password');
                setPasswordcolor('red');

            } else {
                setpasswordError('Strong Password'); // Clear error if all conditions are met
                setPasswordcolor('green');

            }
        };



        const handleCopy = () => {
            navigator.clipboard.writeText(ConfirmPassword)
              .then(() => {
                // Success feedback
                setCopypassword('Copied');
                ;
              })
              .catch(err => {
                // Error feedback
                setCopypassword('Failed to copy');
               
              });
          };

        




    return (
        <>

            <Modal
                open={props.open}

                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>

                    <Box sx={FormStyle} >
                        <div>
                            <Typography sx={{
                                fontSize: '24px',
                                fontWeight: '600',
                                fontFamily: 'Poppins',
                                color: '#2c3a52',
                            }}>
                                New Intern
                            </Typography>
                            <Typography sx={{
                                position: 'relative',
                                fontSize: '14px',
                                fontFamily: 'Poppins',
                                color: '#2c3a52',

                            }}>
                                Setup Credentials
                            </Typography>
                        </div>
                        <div>
                            <img alt="close" height={'15px'} src={CloseIcon} style={{ cursor: 'pointer' }} onClick={props.handleClose}></img>
                        </div>


                    </Box>

                    <Box sx={FormStyle2}>
                        <div>
                            <form  onSubmit={HandelCreateIntern}>
                                <div class="form-group">
                                    <label for="exampleFormControlInput1"
                                        style={{
                                            fontSize: '20px',
                                            fontWeight: '500',
                                            fontFamily: 'Poppins',
                                            color: '#343434'
                                        }}
                                    >Intern Name</label>
                                    <input type="text" value={Name} onChange={(e) => setName(e.target.value)} class="form-control" id="exampleFormControlInput1" placeholder="E.g. John Snow"
                                        style={{
                                            borderStyle: 'none',
                                            outline: 'none',
                                            lineHeight: '31.39px',
                                            fontWeight: '500',
                                            fontFamily: 'Poppins',
                                            color: '#343434',
                                            fontSize: '16px',
                                            backgroundColor: "#F8F8F8"
                                        }}
                                    ></input>
                                </div>
                                <p class='text-danger'>{error}</p>
                                <div class="form-group">
                                    <label for="exampleFormControlInput1"
                                        style={{
                                            fontSize: '20px',
                                            fontWeight: '500',
                                            fontFamily: 'Poppins',
                                            color: '#343434'
                                        }}
                                    >Email</label>
                                    <input type="email" value={Email} onChange={(e) => {setEmail(e.target.value);HandelEmail()}} class="form-control" id="exampleFormControlInput1" placeholder="E.g. John@example.com"
                                        style={{
                                            borderStyle: 'none',
                                            outline: 'none',
                                            lineHeight: '31.39px',
                                            fontWeight: '500',
                                            fontFamily: 'Poppins',
                                            color: '#343434',
                                            fontSize: '16px',
                                            backgroundColor: "#F8F8F8"
                                        }}
                                    ></input>
                                </div>
                                <small style={{color:'red'}}>{emailerror}</small>
                                <div class="form-group">
                                    <label for="exampleFormControlInput1"
                                        style={{
                                            fontSize: '20px',
                                            fontWeight: '500',
                                            fontFamily: 'Poppins',
                                            color: '#343434'
                                        }}
                                    >Set Password</label>
                                    <div class="form-control d-flex align-items-center" style={{ backgroundColor: "#F8F8F8" }}>
                                        <input type={showpssword == true ? 'text' : "password"} value={Password} onChange={(e) => {setPassword(e.target.value);Handelpassword()}} class="form-control" id="exampleFormControlInput1" placeholder="Minimum 8 letters"
                                            style={{
                                                borderStyle: 'none',
                                                outline: 'none',
                                                lineHeight: '31.39px',
                                                fontWeight: '500',
                                                fontFamily: 'Poppins',
                                                color: '#343434',
                                                fontSize: '16px',
                                                backgroundColor: "#F8F8F8"
                                            }}
                                        ></input>
                                        <img alt="icon" height={'24px'} src={showpssword == true ? openeye : EyeIcon} onClick={() => setShowpssword(!showpssword)}></img>
                                    </div>
                                    <small style={{color:`${passwordcolor}`}}>{passworderror}</small>
                                </div>
                                <div style={{
                                    width: '100%',
                                    position: 'relative',
                                    fontSize: '11px',
                                    fontFamily: 'Poppins',
                                    color: '#3e3e3e',
                                }}>
                                    Password must contain: Min 8 Characters, Max 15 Characters, 1 Lowercase, 1 Uppercasse, 1 Number.
                                </div>
                                <div class="form-group">
                                    <label for="exampleFormControlInput1"
                                        style={{
                                            fontSize: '20px',
                                            fontWeight: '500',
                                            fontFamily: 'Poppins',
                                            color: '#343434'

                                        }}
                                    >Confirm Password</label>
                                    <div class="form-control d-flex align-items-center" style={{ backgroundColor: "#F8F8F8" }}>
                                        <input type={showconfirmpssword == true ? 'text' : "password"} value={ConfirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} class="form-control" id="exampleFormControlInput1" placeholder="Re-enter the password"
                                            style={{
                                                borderStyle: 'none',
                                                outline: 'none',
                                                lineHeight: '31.39px',
                                                fontWeight: '500',
                                                fontFamily: 'Poppins',
                                                color: '#343434',
                                                fontSize: '16px',
                                                backgroundColor: "#F8F8F8"
                                            }}
                                        ></input>
                                        <img alt="icon" height={'24px'}src={showconfirmpssword == true ? openeye : EyeIcon} onClick={() => setShowconfirmpssword(!showconfirmpssword)}></img>
                                    </div>
                                    <small style={{color:`${confirmpasswordcolor}`}}>{confirm}</small>
                                </div>
                                <small>{copypassword}</small>
                                <div onClick={handleCopy} style={{
                                    position: 'relative',
                                    display: 'flex',
                                    justifyContent: "flex-end",
                                    gap: '10px',
                                    cursor: 'pointer',
                                }}>
                                    <img src={CopyIcon} alt="icon" height={'24px'}></img>
                                    <span
                                        style={{
                                            position: 'relative',
                                            fontSize: '16px',
                                            lineHeight: '31.39px',
                                            fontFamily: 'Poppins',
                                            color: '#343434',
                                        }}
                                    >Copy Password</span>
                                </div>

                                <Stack direction={'row'}>
                                    <Button sx={Buttunstyle} type="submit">Confirm</Button>
                                </Stack>
                            </form>
                        </div>
                    </Box>
                </Box >
            </Modal >
         <Toaster interncreatesuccessfully={interncreatesuccessfully}></Toaster>
        </>
    )
};

export default NewIntern;