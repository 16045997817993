import React, { useContext, useEffect, useRef, useState } from 'react';

import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Lowtechlogo from '../../../Assets/Images/SideBarLowTechLogo.png'
import Qcimage1 from '../../../Assets/Images/QcSidebarimage1.svg'
import Qcimage2 from '../../../Assets/Images/QcSidebarImage2.svg'
import QcProfile from '../../../Assets/Images/Profilephoto1.png'
import SideBaricon1 from '../../../Assets/Images/DashboardIcon.svg'
import Sidebaricon2 from '../../../Assets/Images/ListIcon1.svg'
import { borderRadius, fontSize } from '@mui/system';
import Sidebaricon3 from '../../../Assets/Images/ListIcon3.svg'
import { Link, useNavigate } from 'react-router-dom';
import { UseContext } from '../../Context/Context';
import Qcstyle from '../../QC/QcSideBar/QcSidebar.module.css'
import Toaster from '../../Toaster/Toaster';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import { CheckSessionLogin, DeleteAllNotification, DeleteNotifcationByID, GetNotifcationbyEmail } from '../../BaseApi/Baseapi';
import Badge from '@mui/material/Badge';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faX } from '@fortawesome/free-solid-svg-icons';
import { LinearProgress } from '@mui/material';



function QcSideBar({ buttonstyle }) {
    const [open, setOpen] = React.useState(true);
    const [qcname, setQcName] = useState('');
    const [qcRoll, setQcRoll] = useState('');
    const [qclogout, setQcLogout] = useState(false);
    const Navigate = useNavigate();
    const { setIsAuthorized } = useContext(UseContext);
    const [confirmationvisible, setConfirmationVisible] = useState(false);
    const toast = useRef(null);
    const [opendrawer, setOpendrawer] = React.useState(false);
    const [notification, setNotification] = useState([]);
    const [notificationID, setNotificationID] = useState();



    const ListLabel = {
        position: 'relative',
        fontSize: '16px',
        fontweight: 500,
        fontFamily: 'Poppins',
        color: '#424242',
    }
    const ListButton = {
        position: 'relative',
        display: 'flex',
        gap: '5px',
        height: '48px',
        borderRadius: '4px',
        alignItems: 'center',
        padding: '10px 16px',
        cursor: "pointer",

        // '&:hover': {
        //     backgroundColor: '#fec83b',

        // },

    };

    const Logout = {
        width: '90%',
        position: 'absolute',
        borderRadius: '4px',
        border: '1px solid #424242',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex - start',
        padding: '10px 16px',
        textAlign: 'left',
        fontSize: '16px',
        color: '#424242',
        fontFamily: 'Poppins',
        height: '45px',
        cursor: "pointer",
        bottom: "20px"
    }

    useEffect(() => {
        setQcName(sessionStorage.getItem('QcName'));
        setQcRoll(sessionStorage.getItem('QcRoll'))
    }, [])
    const LogOut = () => {
        setQcLogout(true);
        setTimeout(() => {
            sessionStorage.clear();
            setIsAuthorized(false);

            // Replace the current route to prevent navigation back
            window.history.pushState(null, '', '/');
            Navigate('/', { replace: true });

        }, 4300)

    }
    useEffect(() => {
        setIsAuthorized(true);
    }, [])

    console.log(buttonstyle, 'buttonstyle')
    const accept = () => {
        LogOut();
    }

    const reject = () => {
        setConfirmationVisible(false);
    }
    //  get Notifcation API By User Email

    const GetNotification = async () => {
        
           
        try {
            const QcToken=sessionStorage.getItem('QcToken');
            const Email = sessionStorage.getItem('qcEmail');
            const API = await fetch(`${GetNotifcationbyEmail}${Email}`, {
                method: 'GET',
                headers: {
                    'token': `${QcToken}`,
                    'Content-Type': 'application/json'
                }
            });
            const Responce = await API.json();
            if (Responce.errorCode === 404) {
                setNotification([]);
            } else {


                setNotification(Responce);
            }

        } catch (error) {

        }
    };

    useEffect(() => {
        GetNotification();
    }, [])

    // ----------------------function for formate millisec to Date ---------------------------------------------------
    const formatDate = (timestamp) => {
        const date = new Date(timestamp);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();

        return `${day}/${month}/${year}`;
    };


    // -------------------------Below API is For CLear All Notification One Time -------------------------------------------

    const AllNotificationDelete = async () => {

        window.location.reload();
        

        try {
            const QcToken=sessionStorage.getItem('QcToken');
            const Email = sessionStorage.getItem('qcEmail');
            const API = await fetch(`${DeleteAllNotification}/${Email}`, {
                method: 'DELETE',
                headers: {
                    'token': `${QcToken}`,
                    'Content-Type': 'application/json'
                }
            });
            const Responce = await API.json();
            // callbackFunction();

        } catch (error) {

        }
    };


    //   ----------------------------------Below code is for drawer----------------------------------
    const toggleDrawer = (newOpen) => () => {
        setOpendrawer(newOpen);
        // GetNotification();
    };

    const DrawerList = (
        <Box sx={{ width: 450, fontSize: 12 }} role="presentation" onClick={toggleDrawer(false)}>
            {notification.length === 0 ?
                <Box sx={{ width: '100%' }}>
                    <LinearProgress sx={{
                        '& .MuiLinearProgress-bar': {
                            backgroundColor: '#fec83b',

                        },
                        backgroundColor: 'transparent',
                    }} />
                </Box>
                :
                <>
                    <Button
                        variant="contained"
                        color="success"
                        sx={{
                            position: 'relative',
                            top: 16,
                            left: 16,
                            fontSize: '10px',
                            fontFamily: 'Poppins',
                            zIndex: 10,

                        }}

                    >
                        Notification
                    </Button>
                    <List>
                        {notification?.sort((a, b) => new Date(b.createdOn) - new Date(a.createdOn))?.map((text, index) => (

                            <ListItem key={text} disablePadding>
                                <ListItemButton>

                                    <ListItemText primary={index + 1} />
                                </ListItemButton>
                                <ListItemButton>
                                    <ListItemText primary={formatDate(text.createdOn)} sx={{ color: '#424242', fontSize: '12px', fontFamily: 'Poppins' }} />

                                </ListItemButton>
                                <ListItemButton>
                                    <ListItemText primary={text.message} sx={{ color: '#424242', fontSize: '12px', fontFamily: 'Poppins' }} />

                                </ListItemButton>
                                <ListItemButton onClick={() => setNotificationID(text.id)}>

                                    <FontAwesomeIcon icon={faX} />
                                </ListItemButton>

                            </ListItem>
                        ))}
                    </List>
                    <Button
                        variant="contained"
                        color="error"
                        sx={{
                            position: 'fixed',
                            bottom: 16,
                            right: 16,
                            fontSize: '10px',
                            fontFamily: 'Poppins',
                            zIndex: 10,
                            display: notification.length > 0 ? 'block' : 'none'
                        }}
                        onClick={() => AllNotificationDelete()}

                    >
                        Clear All
                    </Button>
                </>
            }

        </Box>
    );

    // ----------------------Below code is for delete notification-------------------------------------
    const DeleteNotification = async () => {
        try {
            const API = await fetch(`${DeleteNotifcationByID}${notificationID}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            const Responce = await API.json();
            GetNotification();
        } catch (error) {

        }
    };

    useEffect(() => {
        if (notificationID != null) {
            DeleteNotification();
        }
    }, [notificationID])







    // useEffect(() => {
    //     const handleClickAnywhere = async() => {
    //       try{
    //         const roleName=sessionStorage.getItem('QcRoll');
    //         const userID=sessionStorage.getItem('QcID');
    //         const SessionID=sessionStorage.getItem('QcsessionID');
    //        const Data=await fetch(`${CheckSessionLogin}${roleName}/${userID}/${SessionID}`,{
    //         method:'GET',
    //         headers:{
    //             'Content-Type':'application/json'
    //         }
    //        });

    //        const Responce=await Data.json();

    //        if(Responce===false){
    //         LogOut();
    //        }
    //       }catch(error){

    //       }

    //     };


    //     document.addEventListener('click', handleClickAnywhere);


    //     return () => {
    //       document.removeEventListener('click', handleClickAnywhere);
    //     };
    //   }, []);
    return (
        <>
            <Toaster qclogout={qclogout} />
            <Drawer open={open} sx={{
                '& .MuiDrawer-paper': {
                    boxShadow: 'none',

                },

            }}
                variant="permanent"
                anchor="left" >
                <Box sx={{ height: '100%', position: 'relative', width: "20vw", }}>
                    <Box sx={{ width: '90%', display: "flex", alignItems: "center", justifyContent: "space-between", marginTop: 2 }}>
                        <div>
                            <img alt='logo' height={'58px'} src={Lowtechlogo}></img>
                        </div>
                        <div className={Qcstyle.QcSidebarsupport}>
                            {/* <div
                                style={{
                                    borderRadius: '99px',
                                    backgroundColor: buttonstyle==4?"#fec83b":'#f5f5f5',
                                    padding: "8px",
                                    cursor: "pointer",

                                }}>
                               <Link to='/Dashboard-Qc/Support' > <img alt='image' height={'24px'} src={Qcimage1}></img></Link>
                            </div> */}
                            <Badge badgeContent={notification.length} color="success">
                                <div
                                    style={{
                                        borderRadius: '99px',
                                        backgroundColor: '#f5f5f5',
                                        padding: "8px",
                                        cursor: "pointer",

                                    }} onClick={toggleDrawer(true)} >
                                    <img alt='image' src={Qcimage2} height={'24px'} ></img>
                                </div>
                            </Badge>
                        </div>

                    </Box>
                    <Box sx={{ width: '90%', display: "flex", alignItems: "center", justifyContent: "space-between", margin: 2 }}>
                        <div class='d-flex align-items-center gap-2'>
                            <div>
                                <img alt='profle' style={{ height: "36px", borderRadius: "50%" }} src={QcProfile}></img>
                            </div>
                            <div class='d-flex flex-column'>
                                <span style={
                                    {

                                        position: 'relative',
                                        fontSize: '16px',
                                        fontFamily: 'Poppins',
                                        color: '#424242',
                                    }
                                }> {qcname}</span>
                                <span
                                    style={{
                                        position: 'relative',
                                        borderRadius: '2px',
                                        backgroundColor: '#fec83b',
                                        fontSize: '12px',
                                        color: '#fff',
                                        fontFamily: 'Poppins',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        padding: '2px 16px',
                                    }}>{qcRoll}</span>

                            </div>
                        </div>

                    </Box>
                    <Box sx={{ width: '90%', margin: 2 }}  >


                        <List sx={{ display: 'flex', flexDirection: 'column', gap: '10px', width: "100%", padding: "1px" }}>
                            <Link to='/Dashboard-Qc' style={{ listStyleType: "none", textDecoration: "none", color: "#424242" }} >
                                <ListItem sx={ListButton} style={{ backgroundColor: buttonstyle === 1 ? '#fec83b' : '', }}>


                                    <img alt='icon' src={SideBaricon1} height={'24px'} style={{ color: 'black' }}></img>
                                    <ListItemText>
                                        <div
                                            style={{
                                                FOntSize: '16px',
                                                FontWeight: '500',
                                                fontFamily: 'Poppins',
                                                color: '#424242',
                                                textAlign: 'left',

                                            }}
                                        >Dashboard</div>
                                    </ListItemText>


                                </ListItem>
                            </Link>

                            <Link to='/Dashboard-Qc/QcAssingList' style={{ listStyleType: "none", textDecoration: "none", color: "#424242" }} >
                                <ListItem sx={ListButton} style={{ backgroundColor: buttonstyle === 2 ? '#fec83b' : '', }}>

                                    <img alt='icon' src={Sidebaricon2} height={'24px'} style={{ color: 'black' }}></img>
                                    <ListItemText >
                                        <div
                                            style={{
                                                FOntSize: '16px',
                                                FontWeight: '500',
                                                fontFamily: 'Poppins',
                                                color: '#424242',
                                                textAlign: 'left',
                                            }}
                                        >Assigning List</div>
                                    </ListItemText>

                                </ListItem>
                            </Link>
                            <Link to='/Dashboard-Qc/QcAssingList/ReviewList' style={{ listStyleType: "none", textDecoration: "none", color: "#424242" }}>
                                <ListItem sx={ListButton} style={{ backgroundColor: buttonstyle === 3 ? '#fec83b' : '', }}>

                                    <img alt='icon' src={Sidebaricon3} height={'24px'} style={{ color: 'black' }}></img>
                                    <ListItemText >
                                        <div
                                            style={{
                                                FOntSize: '16px',
                                                FontWeight: '500',
                                                fontFamily: 'Poppins',
                                                color: '#424242',
                                                textAlign: 'left',
                                            }}
                                        >Review List</div>
                                    </ListItemText>

                                </ListItem>
                            </Link>

                            <Link to='/Dashboard-Qc/Support' style={{ listStyleType: "none", textDecoration: "none", color: "#424242" }}>
                                <ListItem sx={ListButton} style={{ backgroundColor: buttonstyle === 4 ? '#fec83b' : '', }}>

                                    <img alt='icon' src={Qcimage1} height={'24px'} style={{ color: 'black' }}></img>
                                    <ListItemText >
                                        <div
                                            style={{
                                                FOntSize: '16px',
                                                FontWeight: '500',
                                                fontFamily: 'Poppins',
                                                color: '#424242',
                                                textAlign: 'left',
                                            }}
                                        >Support</div>
                                    </ListItemText>

                                </ListItem>
                            </Link>
                        </List>

                        <List sx={Logout}>
                            <ListItem onClick={() => setConfirmationVisible(true)} icon="pi pi-check" label="Confirm">
                                Logout
                            </ListItem>
                        </List>
                    </Box>
                </Box>
            </Drawer >

            <Toast ref={toast} />
            <ConfirmDialog
                style={{
                    backgroundColor: "rgb(255, 255, 270)",
                    color: 'black', width: '400px',
                    fontFamily: 'Poppins',
                    fontSize: '14px', padding: '20px',
                    borderRadius: '10px',
                    position: 'absolute',
                    top: '10%',
                    border: '1px solid black'

                }} visible={confirmationvisible} message="Are you sure you want to Logout?"
                icon="pi pi-exclamation-triangle"
                accept={accept}
                acceptClassName="btn btn-success m-2 "
                reject={reject}
                rejectClassName="btn btn-danger m-2"
            />

            <Drawer anchor="right" open={opendrawer} onClose={toggleDrawer(false)}>
                {DrawerList}
            </Drawer>
        </>
    )
}

export default QcSideBar;